import { useState } from "react";
import { customersFields } from "../../constants/formFields";
import Input from "../Input";
import FormAction from "../FormAction";

const fields = customersFields;
let fieldsState = {};
fields.forEach((field) => (fieldsState[field.id] = ""));

export default function Customers({ loading, user, addNewCustomer }) {
  const [customersState, setCustomerState] = useState(fieldsState);

  const handleChange = (e) => {
    setCustomerState({ ...customersState, [e.target.id]: e.target.value });
  };


  const customerValues = {
    name: customersState?.name,
    project_id: customersState?.project_id,
    description: customersState?.description,
    account_manager_arravo_id:user?.arravoId
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    authenticateUser();
    addNewCustomer(customerValues);
  };

  //to handle Login API Integration
  const authenticateUser = () => {};
  return (
    <form
      className="md:mt-8 px-3 w-full md:max-w-md block justify-center space-y-6"
      onSubmit={handleSubmit}
    >
      <div className=" space-y-md w-full">
        {fields.map((field) => (
          <Input
            key={field.id}
            handleChange={handleChange}
            value={customersState[field.id]}
            labelText={field.labelText}
            labelFor={field.labelFor}
            id={field.id}
            name={field.name}
            type={field.type}
            isRequired={field.isRequired}
            placeholder={field.placeholder}
          />
        ))}
      </div>
      <FormAction handleSubmit={handleSubmit} loading={loading} text="Submit" />
    </form>
  );
}
