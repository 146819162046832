import { useState } from "react";
import { changePasswordFields } from "../../constants/formFields";
import Input from "../Input";
import FormAction from "../FormAction";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { Button, Stack } from "@chakra-ui/react";

const fields = changePasswordFields;
let fieldsState = {};
fields.forEach((field) => (fieldsState[field.id] = ""));

export default function ChangePassword({ loading, changePassword }) {
  const [passwordState, setpasswordState] = useState(fieldsState);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordLoading, setPasswordLoading] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible((prev) => !prev);
  };
  const handleChange = (e) => {
    setpasswordState({ ...passwordState, [e.target.id]: e.target.value });
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    setPasswordLoading(true);
    await changePassword(passwordState);
    setPasswordLoading(false);
  };

  //to handle Login API Integration
  const authenticateUser = () => {};
  return (
    <div className="md:mt-8 px-3 w-full md:w-[400px] block space-y-6">
      <div className="space-y-px">
        {fields.map((field) => (
          <div key={field.id} style={{ position: "relative" }}>
            <Input
              handleChange={handleChange}
              value={passwordState[field?.id]}
              labelText={field?.labelText}
              labelFor={field?.labelFor}
              id={field?.id}
              name={field?.name}
              type={
                field?.name === "newPassword" || field?.name === "oldPassword"
                  ? passwordVisible
                    ? "text"
                    : "password"
                  : field.type
              }
              isRequired={field?.isRequired}
              placeholder={field?.placeholder}
            />

            {(field?.name === "newPassword" ||
              field?.name === "oldPassword") && (
              <span
                onClick={togglePasswordVisibility}
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "70%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
              >
                {passwordVisible ? <FiEye /> : <FiEyeOff />}
              </span>
            )}
          </div>
        ))}
      </div>
      <Stack direction="row" spacing={4}>
        <Button
          style={{ width: "100%" }}
          isLoading={passwordLoading}
          loadingText="Submitting"
          colorScheme="red"
          type="submit"
          onClick={handlePasswordSubmit}
        >
          Submit
        </Button>
      </Stack>
    </div>
  );
}
