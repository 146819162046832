/** @format */

const currencies = [
  { value: "NGN", label: "Nigerian Naira (NGN)" },
  { value: "USD", label: "United States Dollar (USD)" },
  { value: "EUR", label: "Euro (EUR)" },
  { value: "GBP", label: "British Pound Sterling (GBP)" },
  { value: "AUD", label: "Australian Dollar (AUD)" },
  { value: "CAD", label: "Canadian Dollar (CAD)" },
  { value: "JPY", label: "Japanese Yen (JPY)" },
  { value: "CHF", label: "Swiss Franc (CHF)" },
  { value: "CNY", label: "Chinese Yuan (CNY)" },
  { value: "SEK", label: "Swedish Krona (SEK)" },
  { value: "NZD", label: "New Zealand Dollar (NZD)" },
  { value: "MXN", label: "Mexican Peso (MXN)" },
  { value: "SGD", label: "Singapore Dollar (SGD)" },
  { value: "HKD", label: "Hong Kong Dollar (HKD)" },
  { value: "NOK", label: "Norwegian Krone (NOK)" },
  { value: "KRW", label: "South Korean Won (KRW)" },
  { value: "TRY", label: "Turkish Lira (TRY)" },
  { value: "INR", label: "Indian Rupee (INR)" },
  { value: "RUB", label: "Russian Ruble (RUB)" },
  { value: "BRL", label: "Brazilian Real (BRL)" },
  { value: "ZAR", label: "South African Rand (ZAR)" },
  { value: "DKK", label: "Danish Krone (DKK)" },
  { value: "AED", label: "United Arab Emirates Dirham (AED)" },
  { value: "PLN", label: "Polish Zloty (PLN)" },
  { value: "THB", label: "Thai Baht (THB)" },
  { value: "ISK", label: "Icelandic Krona (ISK)" },
  { value: "IDR", label: "Indonesian Rupiah (IDR)" },
  { value: "CZK", label: "Czech Koruna (CZK)" },
  { value: "HUF", label: "Hungarian Forint (HUF)" },
  { value: "MYR", label: "Malaysian Ringgit (MYR)" },
  { value: "PHP", label: "Philippine Peso (PHP)" }
];

export default currencies;
