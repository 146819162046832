import { useState } from "react";
import { loginFields } from "../constants/formFields";
import Input from "./Input";
import FormAction from "./FormAction";
import FormExtra from "./FormExtra";
import { toast } from "react-toastify";
import { FiEye, FiEyeOff } from "react-icons/fi";

const fields = loginFields;
let fieldsState = {};
fields.forEach((field) => (fieldsState[field.id] = ""));

export default function Login({ loading, login }) {
  const [loginState, setLoginState] = useState(fieldsState);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible((prev) => !prev);
  };
  const { email } = loginState;

  const handleChange = (e) => {
    setLoginState({ ...loginState, [e.target.id]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    authenticateUser();
    if (email.includes("arravo.co")) {
      login(loginState);
    } else {
      toast.error("Please use the company email");
    }
  };

  //to handle Login API Integration
  const authenticateUser = () => {};

  return (
    <form
      className="mt-8 max-w-md block justify-center space-y-6"
      onSubmit={handleSubmit}
    >
      <div className="space-y-px">
        {fields.map((field) => (
          <div key={field.id} style={{ position: "relative" }}>
            <Input
              handleChange={handleChange}
              value={loginState[field?.id]}
              labelText={field?.labelText}
              labelFor={field?.labelFor}
              id={field?.id}
              name={field?.name}
              type={
                field?.name === "password"
                  ? passwordVisible
                    ? "text"
                    : "password"
                  : field.type
              }
              isRequired={field?.isRequired}
              placeholder={field?.placeholder}
            />
            {field?.name === "password" && (
              <span
                onClick={togglePasswordVisibility}
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "70%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
              >
                {passwordVisible ? <FiEye /> : <FiEyeOff />}
              </span>
            )}
          </div>
        ))}
      </div>
      <FormExtra />
      <FormAction handleSubmit={handleSubmit} loading={loading} text="Login" />
    </form>
  );
}
