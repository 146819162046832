import React, { useState } from "react";
import FormAction from "../FormAction";

export default function ScheduledPost({ loading, addSchedule, customers }) {
  const [scheduledState, setScheduledState] = useState({
    name: "",
    desc: "",
    subjectType: "",
    subjectId: "",
    reminderFrequency: "",
    startOfSchedule: "",
    endOfSchedule: "",
    reminderType: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setScheduledState({
      ...scheduledState,
      [name]: value,
    });
  };
  const { subjectId, ...rest } = scheduledState;
  const handleSubmit = (e) => {
    e.preventDefault();
    authenticateUser();
    addSchedule({
      ...(scheduledState.subjectType === "PERSONAL" ? rest : scheduledState),
    });
  };
  const authenticateUser = () => {};
  return (
    <form
      className="md:mt-8  px-3 w-full  md:max-w-[40rem] block justify-center space-y-6"
      onSubmit={handleSubmit}
    >
      <div className=" space-y-md w-full">
        <div className="flex flex-wrap -mx-2">
          <div className="w-full md:w-1/2 px-2 mb-4">
            <label
              htmlFor="subjectType"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Subject Type
            </label>
            <select
              id="subjectType"
              name="subjectType"
              value={scheduledState.subjectType}
              onChange={handleInputChange}
              className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
            >
              <option value="">select type</option>
              <option value="CUSTOMER">Customer</option>
              <option value="PERSONAL">Personal</option>
            </select>
          </div>
          {scheduledState?.subjectType === "CUSTOMER" && (
            <div className="w-full md:w-1/2 px-2 mb-4">
              <label
                htmlFor="customerId"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Customer
              </label>
              <select
                id="subjectId"
                name="subjectId"
                value={scheduledState.subjectId}
                onChange={handleInputChange}
                className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
              >
                <option value="">select customer</option>
                {customers?.customers?.map((dx, idx) => (
                  <option key={idx} value={dx?._id}>
                    {dx?.name}
                  </option>
                ))}
              </select>
            </div>
          )}

          <div className="w-full md:w-1/2 px-2 mb-4">
            <label
              htmlFor="name"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={scheduledState.name}
              onChange={handleInputChange}
              className="rounded-md appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
              placeholder="Enter your name"
            />
          </div>

          <div className="w-full md:w-1/2 px-2 mb-4">
            <label
              htmlFor="desc"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Description
            </label>
            <input
              type="text"
              id="desc"
              name="desc"
              value={scheduledState.desc}
              onChange={handleInputChange}
              className="rounded-md appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
              placeholder="Enter description"
            />
          </div>

          <div className="w-full md:w-1/2 px-2 mb-4">
            <label
              htmlFor="startOfSchedule"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Start of Schedule
            </label>
            <input
              type="datetime-local"
              id="startOfSchedule"
              name="startOfSchedule"
              value={scheduledState.startOfSchedule}
              onChange={handleInputChange}
              className="rounded-md appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
              placeholder="Enter start of schedule"
            />
          </div>

          <div className="w-full md:w-1/2 px-2 mb-4">
            <label
              htmlFor="endOfSchedule"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              End of Schedule
            </label>
            <input
              type="datetime-local"
              id="endOfSchedule"
              name="endOfSchedule"
              value={scheduledState.endOfSchedule}
              onChange={handleInputChange}
              className="rounded-md appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
              placeholder="Enter end of schedule"
            />
          </div>
          <div className="w-full md:w-1/2 px-2 mb-4">
            <label
              htmlFor="reminderFrequency"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Reminder Frequency
            </label>
            <select
              id="reminderFrequency"
              name="reminderFrequency"
              value={scheduledState.reminderFrequency}
              onChange={handleInputChange}
              className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
            >
              <option value="">select frequency</option>
              {/*<option value="PER_MINUTE">Per Minute</option>*/}
              <option value="PER_HOUR">Per Hour</option>
              <option value="PER_DAY">Per Day</option>
              <option value="PER_MONTH">Per Month</option>
              <option value="PER_YEAR">Per Year</option>
            </select>
          </div>
          <div className="w-full md:w-1/2 px-2 mb-4">
            <label
              htmlFor="reminderType"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Reminder Type
            </label>
            <select
              id="reminderType"
              name="reminderType"
              value={scheduledState.reminderType}
              onChange={handleInputChange}
              className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
            >
              <option value="">select type</option>
              <option value="INTERVAL">Repeat</option>
              <option value="TIMEOUT">Run Once</option>
            </select>
          </div>
        </div>
      </div>
      <FormAction handleSubmit={handleSubmit} loading={loading} text="Submit" />
    </form>
  );
}
