import ChangePassword from "../../components/Dashbord/ChangePassword";
import { connect } from "react-redux";
import { changePasswordFunc } from "../../redux/actions";
import React from "react";
const ChangePasswordPage = ({ loading, changePassword }) => {
  return (
    <>
      <div className="flex w-full justify-center py-6 px-4 sm:px-6 lg:px-12">
        <div className="max-w-md w-full">
          <h4 className="mt-0 text-start text-xl font-extrabold text-gray-900">
            Change Password!!!
          </h4>
          <ChangePassword loading={loading} changePassword={changePassword} />
        </div>
      </div>
    </>
  );
};
const mapState = (state) => ({
  loading: state.auth.loading,
});

const mapDispatch = (dispatch) => ({
  changePassword: (data) => dispatch(changePasswordFunc(data)),
});

export default connect(mapState, mapDispatch)(ChangePasswordPage);
