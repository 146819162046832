import React from "react";
import logo from "../../images/arravo_white_logo.png";
import SidebarItem from "./SidebarItem";
import PAGES_URL from "../../router/routes";
const Sidebar = ({ logoutUser }) => {
  return (
    <>
      <nav
        className={`w-14 sm:w-72 h-full bg-gray-800 hidden md:block relative`}
      >
        <div className="w-full block mx-auto px-6 py-2">
          <div className="text-left mb-6">
            <img
              src={logo}
              alt="Logo"
              className="mx-auto w-[120px] ml-[-5px] mt-2"
            />
          </div>
          <div className="w-full h-full mt-10 flex items-start justify-start text-gray-900 text-xl">
            <ul>
              <SidebarItem to={PAGES_URL.DASHBOARD}>Dashboard</SidebarItem>
              <SidebarItem to={PAGES_URL.DASHBOARD_CONTRACTS}>
                Contracts
              </SidebarItem>
              <SidebarItem to={PAGES_URL.DASHBOARD_CUSTOMERS}>
                Customers
              </SidebarItem>
              <SidebarItem to={PAGES_URL.DASHBOARD_SCHEDULE}>
                Schedules
              </SidebarItem>
              <SidebarItem to={PAGES_URL.DASHBOARD_SETTINGS}>
                Settings
              </SidebarItem>
            </ul>
          </div>
          <div className="flex-grow"></div>
        </div>

        <div className="absolute bottom-0 w-full px-6 pb-4">
          <button
            onClick={() => logoutUser()}
            className="bg-red-600 hover:bg-purple-700 text-white text-sm py-1 px-4 rounded-md w-full"
          >
            Sign Out
          </button>
        </div>
      </nav>
    </>
  );
};

export default Sidebar;
