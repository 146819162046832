/** @format */

import axios from "axios";
import { toast } from "react-toastify";
import PAGES_URL from "../../router/routes";
import {
  AUTHENTICATED,
  LOADING,
  LOADING_UPDATE,
  SET_USER,
} from "../reducers/types";
import history from "./history";
import { redirectPage } from ".";
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
const appToken = localStorage.getItem("ReminderAppToken");

const headers = {
  headers: {
    Authorization: appToken,
  },
};

export const registerUser = (data) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  axios
    .post(`${apiBaseUrl}/users/onboard`, data)
    .then((res) => {
      const { status_code, message } = res?.data;
      if (Number(status_code) !== 201) {
        dispatch({ type: LOADING, payload: null });
        toast.error(message);
      } else {
        dispatch({ type: LOADING, payload: null });
        dispatch({ type: SET_USER, payload: res?.data?.data });
        toast.success(message);
        window.location.href = "/login";
      }
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err?.response?.data?.message);
    });
};

export const loginUser = (data) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  axios
    .post(`${apiBaseUrl}/auth/login/basic`, data)
    .then((res) => {
      const { status_code, message, data } = res?.data;
      if (Number(status_code) !== 200) {
        dispatch({ type: LOADING, payload: null });
        toast.error(message);
      } else {
        const { accessToken, ...rest } = data;
        localStorage.setItem("ReminderAppToken", `Bearer ${accessToken}`);
        setTimeout(() => {
          axios
            .get(`${apiBaseUrl}/me`, {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            })
            .then((resp) => {
              dispatch({ type: LOADING, payload: null });
              if (Number(status_code) !== 200) {
                toast.error(message);
              } else {
                dispatch({ type: AUTHENTICATED });
                toast.success(res?.data?.message);
                dispatch({ type: SET_USER, payload: rest });
                history.push(PAGES_URL.DASHBOARD);
                window.location.reload();
              }
            });
        }, 2000);
      }
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err?.response?.data?.message);
    });
};

export const getUser = () => (dispatch) => {
  axios
    .get(`${apiBaseUrl}/me`, headers)
    .then((res) => {
      const { message, data } = res?.data;
      if (data) {
        dispatch({ type: SET_USER, payload: data });
      }
      // else {
      //     toast.error(message);
      // }
    })
    .catch((err) => {
      console.log("ERRRR", err)
      // window.location.reload();
      if(Number(err?.response?.data?.status_code) == 400){
          redirectPage();
      }else{
      toast.error(err?.message || err?.response?.data?.message);
      }
    });
};

export const updateUser = (data) => (dispatch) => {
  dispatch({ type: LOADING_UPDATE, payload: true });
  return axios
    .put(`${apiBaseUrl}/me`, data, headers)
    .then((res) => {
      const { status_code, message, data } = res?.data;
      dispatch({ type: LOADING_UPDATE, payload: null });
      if (Number(status_code) === 200) {
        toast.success(message);
        dispatch({ type: SET_USER, payload: res?.data?.data });
        setTimeout(() => {
          window.location.reload();
        }, 2500);
      } else {
        toast.error(message);
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message);
    });
};

export const forgotPassword = (data) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  return axios
    .get(`${apiBaseUrl}/auth/password/recovery/initiate?email=${data?.email}`)
    .then((res) => {
      dispatch({ type: LOADING, payload: null });
      const { status_code, message } = res?.data;
      if (Number(status_code) === 200) {
        history.push(PAGES_URL.FORGOT_COMPLETE);
        setTimeout(() => {
          window.location.reload();
        }, 2500);
        toast.success(message);
      } else {
        toast.error(message);
      }
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err?.response?.data?.message);
    });
};

export const resetPasswordComplete = (data) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  axios
    .post(`${apiBaseUrl}/auth/password/recovery/complete`, data)
    .then((res) => {
      dispatch({ type: LOADING, payload: null });
      const { status_code, message } = res?.data;
      if (Number(status_code) === 200) {
        history.push(PAGES_URL.LOGIN);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        toast.success(message);
      } else {
        toast.error(message);
      }
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err?.response?.data?.message);
    });
};

export const changePasswordFunc = (data) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  axios
    .post(`${apiBaseUrl}/auth/password/change`, data, headers)
    .then((res) => {
      dispatch({ type: LOADING, payload: null });
      toast.success(res?.data.message);
      setTimeout(() => {
        window.location.reload();
      }, 2500);
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err?.response?.data?.message);
    });
};
export const logout = () => (dispatch) => {
  localStorage.clear();
  history.push(PAGES_URL.LOGIN);
  window.location.reload();
};
