/** @format */

import {
  LOADING,
  LOADING_UPDATE,
  AUTHENTICATED,
  SET_USER,
  UPDATED,
} from "./types";

const initialState = {
  loading: null,
  loading_update: null,
  authenticated: null,
  user: null,
  updated: false,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: action.payload };
    case LOADING_UPDATE:
      return { ...state, loading: action.payload };
    case AUTHENTICATED:
      return { ...state, authenticated: true };
    case SET_USER:
      return { ...state, user: action.payload };
    case UPDATED:
      return { ...state, updated: !state.updated };
    default:
      return state;
  }
}
