const loginFields = [
  {
    labelText: "Email address",
    labelFor: "email Address",
    id: "email",
    name: "email",
    type: "email",
    autoComplete: "email",
    isRequired: true,
    placeholder: "Email address",
  },
  {
    labelText: "Password",
    labelFor: "password",
    id: "password",
    name: "password",
    type: "password",
    autoComplete: "current-password",
    isRequired: true,
    placeholder: "Password",
  },
];

const signupFields = [
  {
    labelText: "First Name",
    labelFor: "First Name",
    id: "first_name",
    name: "first_name",
    type: "text",
    autoComplete: "first_name",
    isRequired: true,
    placeholder: "First Name",
  },
  {
    labelText: "Last Name",
    labelFor: "Last Name",
    id: "last_name",
    name: "last_name",
    type: "text",
    autoComplete: "last_name",
    isRequired: true,
    placeholder: "Last Name",
  },
  {
    labelText: "Arravo Email address",
    labelFor: "email address",
    id: "email",
    name: "email",
    type: "email",
    autoComplete: "email",
    isRequired: true,
    placeholder: "test@arravo.co",
  },
  {
    labelText: "Arravo ID",
    labelFor: "Arravo Id",
    id: "arravo_id",
    name: "arravo_id",
    type: "arravo_id",
    autoComplete: "arravo_id",
    isRequired: true,
    placeholder: "Enter your arravo Id",
  },
  {
    labelText: "Phone No",
    labelFor: "phone_number",
    id: "phone_number",
    name: "phone_number",
    type: "text",
    autoComplete: "phone_number",
    isRequired: true,
    placeholder: "Phone No",
  },
  {
    labelText: "Password",
    labelFor: "password",
    id: "password",
    name: "password",
    type: "password",
    autoComplete: "current-password",
    isRequired: true,
    placeholder: "Password",
  },
];

const passwordResetFields = [
  {
    labelText: "Email address",
    labelFor: "email address",
    id: "email",
    name: "email",
    type: "email",
    autoComplete: "email",
    isRequired: true,
    placeholder: "Email address",
  },
];

const passwordResetCompleteFields = [
  {
    labelText: "Token",
    labelFor: "token",
    id: "token",
    name: "token",
    type: "text",
    autoComplete: "token",
    isRequired: true,
    placeholder: "Enter Token",
  },
  {
    labelText: "New Password",
    labelFor: "New Password",
    id: "newPassword",
    name: "newPassword",
    type: "password",
    autoComplete: "newPassword",
    isRequired: true,
    placeholder: "New Password",
  },
];

const changePasswordFields = [
  {
    labelText: "Old Password",
    labelFor: "Old Password",
    id: "oldPassword",
    name: "oldPassword",
    type: "password",
    autoComplete: "oldPassword",
    isRequired: true,
    placeholder: "........",
  },
  {
    labelText: "New Password",
    labelFor: "New Password",
    id: "newPassword",
    name: "newPassword",
    type: "password",
    autoComplete: "newPassword",
    isRequired: true,
    placeholder: "........",
  },
];

const customersFields = [
  {
    labelText: "Name",
    labelFor: "Name",
    id: "name",
    name: "name",
    type: "text",
    autoComplete: "name",
    isRequired: true,
    placeholder: "Enter name",
  },
  {
    labelText: "Project ID",
    labelFor: "Project ID",
    id: "project_id",
    name: "project_id",
    type: "text",
    autoComplete: "Project ID",
    isRequired: true,
    placeholder: "ARRAVO-123",
  },
  // {
  //   labelText: "Account Manager Arravo ID",
  //   labelFor: "Account Manager Arravo ID",
  //   id: "account_manager_arravo_id",
  //   name: "account_manager_arravo_id",
  //   type: "text",
  //   autoComplete: "Account Manager Arravo ID",
  //   isRequired: true,
  //   placeholder: "ACCT-ARRAVO-2024",
  // },
  {
    labelText: "Description",
    labelFor: "description",
    id: "description",
    name: "description",
    type: "text",
    autoComplete: "description",
    isRequired: true,
    placeholder: "Enter description",
  },
];
const scheduledPostFields = [
  {
    labelText: "Name",
    labelFor: "Name",
    id: "name",
    name: "name",
    type: "text",
    autoComplete: "name",
    isRequired: true,
    placeholder: "Enter name",
  },
  {
    labelText: "Description",
    labelFor: "description",
    id: "description",
    name: "description",
    type: "text",
    autoComplete: "description",
    isRequired: true,
    placeholder: "Enter description",
  },
  {
    labelText: "Start Of Schedule",
    labelFor: "Start Of Schedule",
    id: "startOfSchedule",
    name: "startOfSchedule",
    type: "date",
    autoComplete: "Start Of Schedule",
    isRequired: true,
    placeholder: "Start Of Schedule",
  },
  {
    labelText: "End Of Schedule",
    labelFor: "End Of Schedule",
    id: "endOfSchedule",
    name: "endOfSchedule",
    type: "date",
    autoComplete: "End Of Schedule",
    isRequired: true,
    placeholder: "End Of Schedule",
  },
  {
    labelText: "Service Due Date",
    labelFor: "Service Due Date",
    id: "serviceDueDate",
    name: "serviceDueDate",
    type: "date",
    autoComplete: "Service Due Date",
    isRequired: true,
    placeholder: "Service Due Date",
  },
];
export {
  loginFields,
  signupFields,
  passwordResetFields,
  passwordResetCompleteFields,
  changePasswordFields,
  customersFields,
  scheduledPostFields,
};
