/** @format */
import React, { useState } from "react";
import DataTableExtensions from "react-data-table-component-extensions";
import DataTable from "react-data-table-component";
import "react-data-table-component-extensions/dist/index.css";
const DatatableComponent = ({ dataRecord, columnsRecord }) => {
  const [limit, setPerLimit] = useState(10);
  const handlePerRowsChanges = (limit) => {
    setPerLimit(limit);
  };

  const data = dataRecord?.slice(0, limit);
  const totalRows = dataRecord?.length;
  const tableData = { columns: columnsRecord, data };
  return (
    <div style={{ height: "auto", width: "100%" }}>
      <DataTableExtensions {...tableData}>
        <DataTable
          columns={columnsRecord}
          data={data}
          noHeader
          defaultSortAsc={false}
          highlightOnHover
          pagination={true}
          paginationServer={true}
          paginationResetDefaultPage={true}
          paginationPerPage={limit}
          paginationTotalRows={totalRows}
          paginationRowsPerPageOptions={[10, 15, 20, 30, 40, 50, 60, 70, 150]}
          onChangeRowsPerPage={handlePerRowsChanges}
          style={{ cursor: "pointer" }}
        />
      </DataTableExtensions>
    </div>
  );
};

export default DatatableComponent;
