/** @format */

const stringifyPrice = (price, noExtra) => {
  const valToStringify = price && price?.toString().split(".")[0];
  const rev = valToStringify?.toString().split("").reverse();

  const newPrice = [];
  for (let i = 0; i < rev?.length; i++) {
    if (
      rev?.length > 3 &&
      i + 1 !== rev?.length &&
      (i + 1) % 3 === 0 &&
      i + 1 >= 3
    ) {
      newPrice?.push(rev[i], ",");
    } else {
      newPrice?.push(rev[i]);
    }
  }

  return `${newPrice?.reverse().join("")}${
    (!noExtra && `.${(price && price?.toString().split(".")[1]) || "00"}`) || ""
  }`;
};

export default stringifyPrice;
