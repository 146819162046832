import Header from "../../components/Header";
import ForgotPasswordComplete from "../../components/ForgotPasswordComplete";
import { connect } from "react-redux";
import { resetPasswordComplete } from "../../redux/actions";
import PAGES_URL from "../../router/routes";
const ForgotPasswordCompletePage = ({ loading, forgotComplete }) => {
  return (
    <div className="flex items-center justify-center py-12 px-4 sm:px-6 lg:px-12">
      <div className=" max-w-md w-full space-y-8">
        <Header
          heading="Complete Reset Password"
          paragraph="Already have an account yet?"
          linkName="Login"
          linkUrl={PAGES_URL.LOGIN}
        />
        <ForgotPasswordComplete
          loading={loading}
          forgotComplete={forgotComplete}
        />
      </div>
    </div>
  );
};

const mapState = (state) => ({
  loading: state.auth.loading,
});

const mapDispatch = (dispatch) => ({
  forgotComplete: (data) => dispatch(resetPasswordComplete(data)),
});

export default connect(mapState, mapDispatch)(ForgotPasswordCompletePage);
