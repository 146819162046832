import React, { useEffect, useState } from "react";
import Sidebar from "../components/Dashbord/Sidebar";
import Header from "../components/Dashbord/Header";
import { connect } from "react-redux";
import { addCustomer, getCustomers, getUser, logout } from "../redux/actions";
import Customers from "../components/Dashbord/Customers";
import formatReadableDate from "../utilities/formatReadableDate";
import DatatableComponent from "../components/DatatableComponent";
import { useNavigate } from "react-router-dom";
import { deleteCustomer } from "../redux/actions/dataActions";
import swal from "sweetalert";

const CustomersPage = ({
  logoutUser,
  loadUser,
  user,
  loading,
  loadCustomer,
  customers,
  addNewCustomer,
  remove,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [limit, setPerLimit] = useState(10);
  const navigate = useNavigate();

  useEffect(() => {
    loadUser();
    loadCustomer();
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleDelete = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        remove(id);
        swal("Deleted!", "scheduled has been deleted!", "success");
      } else {
        swal("Oops!", "scheduled not deleted!", "error");
      }
    });
  };
  const [columns, ] = useState([
    {
      name: "Name",
      selector: "name",
      sortable: true,
    },
    {
      name: "Description",
      selector: "description",
      sortable: true,
    },
    {
      name: "Created",
      cell: (row) => formatReadableDate(row?.createdAt),
      sortable: true,
    },
    {
      cell: (row) => (
        <button
          className="group relative flex justify-center py-2 px-4 border border-transparent text-[10px] font-medium rounded-md text-white bg-purple-700"
          onClick={() => {
            navigate(`/dashboard/customers/${row?.id}`);
          }}
        >
          View
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    // {
    //   cell: (row) => (
    //     <button
    //       className="group relative flex justify-center py-2 px-4 border border-transparent text-[10px]  font-medium rounded-md text-white bg-red-600"
    //       onClick={() => handleDelete(row?.id)}
    //     >
    //       Delete
    //     </button>
    //   ),
    //   ignoreRowClick: true,
    //   allowOverflow: true,
    //   button: true,
    // },
  ]);

  return (
    <div className="flex h-screen bg-green-300">
      <div className="flex-1 flex flex-col overflow-hidden">
        <div className="flex h-full">
          <Sidebar logoutUser={logoutUser} loadUser={loadUser} />
          <main className="flex flex-col w-full bg-white overflow-x-hidden overflow-y-auto">
            <Header logoutUser={logoutUser} user={user} name={"Customers"} />
            <div className="flex justify-end mt-[-10px] px-5">
              <button
                className={`group relative flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 mt-10`}
                onClick={openModal}
              >
                Add Customer
              </button>
            </div>
            <div className="flex justify-center w-full mx-auto py-5 px-5">
              <DatatableComponent
                columnsRecord={columns}
                dataRecord={customers?.customers}
                limit={limit}
              />
            </div>
          </main>
        </div>
      </div>
      {/* ScheduledPostModal */}
      {isModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="bg-white w-[500px] p-8 rounded-lg shadow-lg">
            <div className="flex justify-between items-center">
              <h2 className="text-xl font-semibold mb-2">Add New Customer</h2>
              <button
                onClick={closeModal}
                className="text-white text-sm bg-red-500 px-2 h-6 rounded-lg cursor-pointer"
              >
                X
              </button>
            </div>
            <Customers loading={loading} addNewCustomer={addNewCustomer} user={user} />
          </div>
        </div>
      )}
    </div>
  );
};
const mapState = (state) => ({
  user: state.auth.user,
  loading: state.auth.loading,
  customers: state.data.customers,
});

const mapDispatch = (dispatch) => ({
  logoutUser: () => dispatch(logout()),
  loadUser: () => dispatch(getUser()),
  addNewCustomer: (data) => dispatch(addCustomer(data)),
  loadCustomer: () => dispatch(getCustomers()),
  remove: (id) => dispatch(deleteCustomer(id)),
});

export default connect(mapState, mapDispatch)(CustomersPage);
