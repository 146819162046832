/** @format */

export const LOADING = "LOADING";
export const LOADING_UPDATE = "LOADING_UPDATE";
export const AUTHENTICATED = "AUTHENTICATED";
export const SET_USER = "SET_USER";
export const UPDATED = "UPDATED";

export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const GET_CUSTOMER = "GET_CUSTOMER";
export const GET_SCHEDULES = "GET_SCHEDULES";
export const GET_SCHEDULE = "GET_SCHEDULE";
export const GET_CONTRACTS = "GET_CONTRACTS";
export const GET_CONTRACT = "GET_CONTRACT";
