const generateCronPattern = (dateString, timeString) => {
  const date = new Date(`${dateString}T${timeString}`);

  const minute = date.getMinutes();
  const hour = date.getHours();
  const dayOfMonth = date.getDate();
  const month = date.getMonth() + 1; // Note: Months are zero-indexed in JavaScript
  const dayOfWeek = date.getDay();

  const generatePattern = (value) => {
    return value === "*" ? "*" : value.toString();
  };

  return [
    {
      minute: generatePattern(minute),
      hour: generatePattern(hour),
      day_of_month: generatePattern(dayOfMonth),
      month: generatePattern(month),
      day_of_week: generatePattern(dayOfWeek),
    }
  ];
};

 export default generateCronPattern;


// const generateCronPattern = (dateString, timeString) => {
//   const date = new Date(`${dateString}T${timeString}`);

//   const minute = date.getMinutes();
//   const hour = date.getHours();
//   const dayOfMonth = date.getDate();
//   const month = date.getMonth() + 1; // Note: Months are zero-indexed in JavaScript
//   const dayOfWeek = date.getDay();

//   const generatePattern = (value) => {
//     return value === "*" ? "*" : value.toString();
//   };

//   const cronPattern = {
//     minute: generatePattern(minute),
//     hour: generatePattern(hour),
//     day_of_month: generatePattern(dayOfMonth),
//     month: generatePattern(month),
//     day_of_week: generatePattern(dayOfWeek),
//   };

//   return cronPattern;
// };

// export default generateCronPattern;


// const generateCronPattern = (dateString, timeString) => {
//   const date = new Date(`${dateString}T${timeString}`);

//   const minute = date.getMinutes();
//   const hour = date.getHours();
//   const dayOfMonth = date.getDate();
//   const month = date.getMonth() + 1; // Note: Months are zero-indexed in JavaScript
//   const dayOfWeek = date.getDay();


//   const generatePattern = (value) => {
//     return value === "*" ? "*" : `${value}\/1`;
//   };

//   const cronPattern = {
//     minute: generatePattern(minute),
//     hour: generatePattern(hour),
//     day_of_month: generatePattern(dayOfMonth),
//     month: generatePattern(month),
//     day_of_week: generatePattern(dayOfWeek),
//   };

//   return cronPattern;
// }

// export default generateCronPattern;



// const generateCronPattern = (dateTimeString) => {
//   const date = new Date(dateTimeString);

//   const minute = date.getMinutes();
//   const hour = date.getHours();
//   const dayOfMonth = date.getDate();
//   const month = date.getMonth() + 1; // Note: Months are zero-indexed in JavaScript
//   const dayOfWeek = date.getDay();

//   const generatePattern = (value) => {
//     return value === "*" ? "*" : `${value}\/1`;
//   };

//   const cronPattern = [
//     {
//       minute: generatePattern(minute),
//       hour: generatePattern(hour),
//       day_of_month: generatePattern(dayOfMonth),
//       month: generatePattern(month),
//       day_of_week: generatePattern(dayOfWeek),
//     },
//   ];

//   return cronPattern;
// }

// export default generateCronPattern;


