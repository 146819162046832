import React, { useEffect, useState } from "react";
import Sidebar from "../components/Dashbord/Sidebar";
import Header from "../components/Dashbord/Header";
import { connect } from "react-redux";
import { addContract, getContracts, getUser, logout } from "../redux/actions";
import Contract from "../components/Dashbord/Contracts";
import PostSchedule from "../components/Dashbord/PostSchedule";
import formatReadableDate from "../utilities/formatReadableDate";
import stringifyPrice from "../utilities/stringifyPrice";
import generateCronPattern from "../utilities/generateCronPattern";
import DatatableComponent from "../components/DatatableComponent";
import { useNavigate } from "react-router-dom";
import { addScheduledPost, deleteContract, getCustomers} from "../redux/actions/dataActions";
import swal from "sweetalert";

const ContractsPage = ({
  logoutUser,
  loadUser,
  user,
  loading,
  loadContracts,
  contracts,
  addNewContract,
  remove,
  addSchedule,
  loadCustomer,
  customers,
}) => {
  const [postModalOpen, setPostModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [contractObj, setContractObj] = useState(null);
  const [limit, setPerLimit] = useState(10);
  const navigate = useNavigate();

  useEffect(() => {
    loadUser();
    loadContracts();
    loadCustomer();
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const closePostModal = () => {
    setPostModalOpen(false);
  };

  const handleSchedulePost = (obj) => {
    setContractObj(obj)

    setPostModalOpen(true);
  };

  const handleDelete = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this item!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        // Perform the delete action with the provided id
        remove(id);
        swal("Deleted!", "scheduled has been deleted!", "success");
        // You can also add any additional logic after the item is deleted
      } else {
        swal("Oops!", "scheduled not deleted!", "error");
      }
    });
  };
  const [columns, setColumns] = useState([
    {
      name: "Name",
      selector: "name",
      sortable: true,
    },
    {
      name: "Currency",
      cell: (row) => row?.currency ? row?.currency : 'NGN',
      sortable: true,
    },
    {
      name: "Value",
      cell: (row) => stringifyPrice(row?.value),
      sortable: true,
    },
    {
      name: "Onset Date",
      cell: (row) => formatReadableDate(row?.onset),
      sortable: true,
    },
    {
      name: "Expiry Date",
      cell: (row) => formatReadableDate(row?.expiry),
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <button
          className="group relative flex justify-center py-2 px-4 border border-transparent text-[10px] font-medium rounded-md text-white bg-blue-700"
          onClick={() => handleSchedulePost(row)}
        >
          Add schedule
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      cell: (row) => (
        <button
          className="group relative flex justify-center py-2 px-4 border border-transparent text-[10px] font-medium rounded-md text-white bg-purple-700"
          onClick={() => {
            navigate(`/dashboard/contracts/${row?.id}`);
          }}
        >
          View
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    // {
    //   cell: (row) => (
    //     <button
    //       className="group relative flex justify-center py-2 px-4 border border-transparent text-[10px]  font-medium rounded-md text-white bg-red-600"
    //       onClick={() => handleDelete(row.id)}
    //     >
    //       Delete
    //     </button>
    //   ),
    //   ignoreRowClick: true,
    //   allowOverflow: true,
    //   button: true,
    // },
  ]);



  return (
    <div className="flex h-screen bg-green-300">
      <div className="flex-1 flex flex-col overflow-hidden">
        <div className="flex h-full">
          <Sidebar logoutUser={logoutUser} loadUser={loadUser} />
          <main className="flex flex-col w-full bg-white overflow-x-hidden overflow-y-auto">
            <Header logoutUser={logoutUser} user={user}  name={"Contracts"} />

            <div className="flex justify-end mt-[-10px] px-5">
              <button
                className={`group relative flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 mt-10`}
                onClick={openModal}
              >
                Add Contract
              </button>
            </div>
            <div className="flex justify-center w-full mx-auto py-5 px-5">
              <DatatableComponent
                columnsRecord={columns}
                dataRecord={contracts}
                limit={limit}
              />
            </div>
          </main>
        </div>
      </div>
      {/* Add Contract Modal */}
      {isModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div class="bg-white w-[480px] h-[550px] overflow-y-auto p-6 rounded-lg shadow-lg scrollbar-red">
            <div className="flex justify-between items-center">
              <h2 className="text-xl font-semibold mb-2"> ADD CONTRACT</h2>

              <button
                onClick={closeModal}
                className="text-white text-sm bg-red-500 px-2 h-6 rounded-lg cursor-pointer"
              >
                X
              </button>
            </div>
            <Contract loading={loading} addNewContract={addNewContract} customers={customers} />
          </div>
        </div>
      )}

      <div>
        {/* ScheduledPostModal */}
        {postModalOpen && (
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
            <div class="bg-white w-[480px] overflow-y-auto p-6 rounded-lg shadow-lg scrollbar-red">
              <div className="flex justify-between items-center">
                <h2 className="text-xl font-semibold mb-2">
                  ADD SCHEDULE
                </h2>

                <button
                  onClick={closePostModal}
                  className="text-white text-sm bg-red-500 px-2 h-6 rounded-lg cursor-pointer"
                >
                  X
                </button>
              </div>
              <PostSchedule loading={loading} addSchedule={addSchedule} contractObj={contractObj} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapState = (state) => ({
  user: state.auth.user,
  loading: state.auth.loading,
  contracts: state.data.contracts,
  customers: state.data.customers,

});

const mapDispatch = (dispatch) => ({
  logoutUser: () => dispatch(logout()),
  loadUser: () => dispatch(getUser()),
  loadContracts: () => dispatch(getContracts()),
  remove: (id) => dispatch(deleteContract(id)),
  addNewContract: (data) => dispatch(addContract(data)),
  addSchedule: (data) => dispatch(addScheduledPost(data)),
  loadCustomer: () => dispatch(getCustomers()),

});

export default connect(mapState, mapDispatch)(ContractsPage);
