import React, { useEffect, useState } from "react";
import Sidebar from "../components/Dashbord/Sidebar";
import Header from "../components/Dashbord/Header";
import { connect } from "react-redux";
import {
  getCustomersById,
  getUser,
  logout,
  updateCustomer,
} from "../redux/actions";
import { textSlice } from "../utilities/textSlice";
import formatReadableDate from "../utilities/formatReadableDate";
import FormAction from "../components/FormAction";
import { useParams } from "react-router-dom";
import { Textarea } from '@chakra-ui/react'

const EditCustomer = ({
  loading,
  logoutUser,
  loadUser,
  user,
  getCustomer,
  customer,
  editCustomer,
}) => {
  const { id } = useParams();
  const [userData, setUserData] = useState(null);
  const [customerState, setCustomerState] = useState({
    name: "",
    description: "",
  
  });

  // Assuming you are populating the state from the API response
  useEffect(() => {
    if (customer && customer.customer) {
      const { name, description } =
        customer?.customer;
      setCustomerState({
        name: name || "",
        description: description || "",
       
      });
    }
  }, [customer]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCustomerState({
      ...customerState,
      [name]: value,
    });
  };

  useEffect(() => {
    loadUser();
    getCustomer(id);
  }, []);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("state"));
    if (items) {
      setUserData(items);
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    editCustomer(customerState, id);
  };
  return (
    <div className="flex h-screen bg-green-300">
      <div className="flex-1 flex flex-col overflow-hidden">
        <div className="flex h-full">
          <Sidebar logoutUser={logoutUser} loadUser={loadUser} />
          <main className="flex flex-col w-full bg-white overflow-x-hidden overflow-y-auto">
            <Header logoutUser={logoutUser} user={user} name={"Settings"} />
            <div className=" flex justify-center w-full mx-auto ">
            <div
                className="w-5/12 ml-6 px-6"
                style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
              >                <h4 className="mt-6  text-left text-xl font-extrabold text-gray-900">
                CUSTOMER INFORMATION
                </h4>
                <div className="mt-6">
                  <h1>
                    <b>Name:</b> {customerState?.name}
                  </h1>
                  <h1 className="mt-4">
                    <b>Description:</b> {textSlice(customerState?.description)}
                  </h1>
                  {/* <h1 className="mt-4">
                    <b>service Start Date:</b>{" "}
                    {formatReadableDate(customerState?.serviceStartDate)}
                  </h1>
                  <h1 className="mt-4">
                    <b>service Start Date:</b>{" "}
                    {formatReadableDate(customerState?.serviceDueDate)}
                  </h1> */}
                </div>
              </div>
              <div
                className="w-7/12 ml-6 p-6"
                style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
              >                <h4 className="mt-6  text-left text-xl font-extrabold text-gray-900">
                  UPDATE CUSTOMER
                </h4>
                <form
                  className="md:mt-8 w-full md:max-w-[40rem] block justify-center space-y-6"
                  onSubmit={handleSubmit}
                >
                  <div className=" space-y-md w-full">
                    <div className="flex flex-wrap -mx-2">
                      <div className="w-full px-2 mb-4">
                        <label
                          htmlFor="name"
                          className="block text-gray-700 text-sm font-bold mb-2"
                        >
                          Name
                        </label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          value={customerState?.name}
                          onChange={handleInputChange}
                          className="rounded-md appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
                          placeholder="Enter your name"
                        />
                      </div>
                      <div className="w-full px-2 mb-4">
                        <label
                          htmlFor="description"
                          className="block text-gray-700 text-sm font-bold mb-2"
                        >
                          Description
                        </label>
                        <Textarea  id="description"
                          name="description"
                          value={customerState?.description}
                          onChange={handleInputChange} placeholder='Here is a sample placeholder' />
                      
                      
                      </div>

                      {/* <div className="w-full px-2 mb-4">
                                                    <label htmlFor="serviceStartDate" className="block text-gray-700 text-sm font-bold mb-2">
                                                    Service Start Date
                                                    </label>
                                                    <input
                                                        type="date"
                                                        id="serviceStartDate"
                                                        name="serviceStartDate"
                                                        defaultValue={serviceDueDate}
                                                        onChange={handleInputChange}
                                                        className="rounded-md appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
                                                        placeholder=""
                                                        disabled

                                                    />
                                                </div>
                                                <div className="w-full px-2 mb-4">
                                                    <label htmlFor="serviceDueDate" className="block text-gray-700 text-sm font-bold mb-2">
                                                    Service Due Date
                                                    </label>
                                                    <input
                                                        type="date"
                                                        id="serviceDueDate"
                                                        name="serviceDueDate"
                                                        defaultValue={serviceDueDate}
                                                        onChange={handleInputChange}
                                                        className="rounded-md appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
                                                        placeholder=""
                                                        disabled

                                                    />
                                                </div> */}
                    </div>
                  </div>
                  <FormAction
                    handleSubmit={handleSubmit}
                    loading={loading}
                    text="Submit"
                  />
                </form>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};
const mapState = (state) => ({
  user: state.auth.user,
  customer: state.data.customer,
  loading: state.auth.loading,
});

const mapDispatch = (dispatch) => ({
  logoutUser: () => dispatch(logout()),
  editCustomer: (data, id) => dispatch(updateCustomer(data, id)),
  getCustomer: (id) => dispatch(getCustomersById(id)),
  loadUser: () => dispatch(getUser()),
});

export default connect(mapState, mapDispatch)(EditCustomer);
