import { useState } from "react";
import { passwordResetFields } from "../constants/formFields";
import Input from "./Input";
import FormAction from "./FormAction";
import { toast } from "react-toastify";

const fields = passwordResetFields;
let fieldsState = {};
fields.forEach((field) => (fieldsState[field.id] = ""));

export default function ForgotPassword({ loading, forgot }) {
  const [passwordResetState, setPasswordResetState] = useState(fieldsState);

  const handleChange = (e) => {
    setPasswordResetState({
      ...passwordResetState,
      [e.target.id]: e.target.value,
    });
  };
  const { email } = passwordResetState;

  const handleSubmit = (e) => {
    e.preventDefault();
    authenticateUser();
    if (email.includes("arravo.co")) {
      forgot(passwordResetState);
    } else {
      toast.error("Please use the company email");
    }
  };

  //to handle Password Reset API Integration
  const authenticateUser = () => {};

  return (
    <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
      <div className="-space-y-px">
        {fields.map((field) => (
          <Input
            key={field.id}
            handleChange={handleChange}
            value={passwordResetState[field.id]}
            labelText={field.labelText}
            labelFor={field.labelFor}
            id={field.id}
            name={field.name}
            type={field.type}
            isRequired={field.isRequired}
            placeholder={field.placeholder}
          />
        ))}
      </div>
      <FormAction
        handleSubmit={handleSubmit}
        loading={loading}
        text="Reset Password"
      />
    </form>
  );
}
