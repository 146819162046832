import React, { useState } from "react";
import PAGES_URL from "../../router/routes";
import SidebarItem from "./SidebarItem";

const Header = ({ logoutUser, name, user }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <>
      <header className="bg-white shadow-md p-4 sticky top-0 z-10 relative">
        <div className="flex justify-between items-center">
          <button onClick={toggleSidebar} className="sm:hidden">
            {isSidebarOpen ? "✕" : "☰"}
          </button>
          <h1 className="text-[18px] ml-3 md:ml-0 font-semibold">{name}</h1>
          <div className="text-gray-500 ml-auto flex items-center">
            <div className="rounded-full bg-purple-500 text-white w-8 h-8 flex items-center justify-center">
              {user?.firstName?.charAt(0)?.toUpperCase()}
              {user?.lastName?.charAt(0)?.toUpperCase()}
            </div>
            <p className="ml-2">Welcome, {user?.lastName}</p>
          </div>
        </div>
      </header>
      {isSidebarOpen && (
        <div
          onClick={toggleSidebar}
          className={`fixed top-0 right-0 w-full h-screen bg-transparent mt-[50px] z-30 transform ${
            isSidebarOpen ? "translate-x-0" : "translate-x-full"
          } transition-transform`}
        >
          <div className="bg-white shadow-md p-4 mt-4">
            <ul>
              <SidebarItem to={PAGES_URL.DASHBOARD}>Dashboard</SidebarItem>
              <SidebarItem to={PAGES_URL.DASHBOARD_CONTRACTS}>
                Contracts
              </SidebarItem>
              <SidebarItem to={PAGES_URL.DASHBOARD_CUSTOMERS}>
                Customers
              </SidebarItem>
              <SidebarItem to={PAGES_URL.DASHBOARD_SCHEDULE}>
                Schedule Post
              </SidebarItem>
              <SidebarItem to={PAGES_URL.DASHBOARD_SETTINGS}>
                Settings
              </SidebarItem>

              <div className="bottom-0 w-[40%] px-0 pb-4">
                <button
                  onClick={() => logoutUser()}
                  className="bg-red-600 hover:bg-purple-700 text-white text-sm py-1 px-3 rounded-md w-full"
                >
                  Sign Out
                </button>
              </div>
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
