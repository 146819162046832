import { useState } from "react";
import FormAction from "../FormAction";
import generateCronPattern from "../../utilities/generateCronPattern";
import { Select, Button, Input, Textarea, Stack } from "@chakra-ui/react";
import currencies from "../../utilities/currencies";
// import Select from "react-select";
export default function Contracts({ loading, customers, addNewContract }) {
  const [base64Image, setBase64Image] = useState([]);
  const [formattedValue, setFormattedValue] = useState("");
  const [contractState, setContractState] = useState({
    name: "",
    currency: "NGN", // Set default currency to NGN
    value: "",
    issued_at: "",
    onset: "",
    expiry: "",
    repeat: "",
    project_id: "",
    description: "",
    files: null, // Initialize files as an empty array
  });

  console.log("contractState", contractState);
  const handleInputChange = async (e) => {
    const { id, value } = e.target;

    if (e.target.type === "file") {
      const files = e.target.files;
      // Convert each file to base64
      const base64Array = await Promise.all(
        Array.from(files).map(async (file) => {
          return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              resolve({
                file: file,
                base64: reader.result,
              });
            };
            reader.readAsDataURL(file);
          });
        })
      );

      // Update state with the array of base64 files
      setBase64Image(base64Array);

      // Save the array of selected files into the state
      setContractState((prevState) => {
        return {
          ...prevState,
          files: Array.from(files), // Save the array of selected files
        };
      });
    } else if (id === "value") {
      // Format the input value for display
      const unformattedValue = value.replace(/,/g, ""); // Remove commas for storing in state
      const formatted = parseFloat(unformattedValue).toLocaleString(); // Format value for display
      setFormattedValue(formatted);
      setContractState((prevState) => ({
        ...prevState,
        [id]: unformattedValue, // Store unformatted value in state
      }));
    } else {
      // For other fields, handle normally
      setContractState((prevState) => ({
        ...prevState,
        [id]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create a FormData object
    const formData = new FormData();

    // Append each field to the FormData object
    Object.entries(contractState).forEach(([key, value]) => {
      // Exclude the files from being directly appended
      if (key !== "files") {
        formData?.append(key, value);
      }
    });

    // Append each file to the FormData object
    contractState?.files?.forEach((file, index) => {
      // Use a unique key for each file
      formData.append(`files`, file);
    });

    // Send the FormData to the addNewContract function
    await addNewContract(formData);
  };
 
  return (
    <form
      className="md:mt-8 w-full block justify-center space-y-6"
      onSubmit={handleSubmit}
    >
      <div className="space-y-md w-full">
        <div className="flex flex-wrap">
          <div className="w-full px-2 mb-4">
            <label
              htmlFor="name"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Name
            </label>
            <Input
              type="text"
              id="name"
              name="name"
              onChange={handleInputChange}
              className="rounded-md appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
              placeholder="Enter the name"
            />
          </div>
          <div className="w-full px-2 mb-4">
            <label
              htmlFor="value"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Value
            </label>
            <div className="flex justify-center">
              <Select
                id="currency"
                name="currency"
                onChange={handleInputChange}
                width={"120px"}
                className="w-[100px] rounded-md appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
              >
                {currencies?.map((data, idx) => (
                  <option key={idx} value={data?.value}>
                    {data?.value}
                  </option>
                ))}
              </Select>
              <Input
                type="text" // Change type from "number" to "text"
                id="value"
                name="value"
                onChange={handleInputChange}
                value={formattedValue}
                className="rounded-md appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
                placeholder="Enter contract worth"
              />
            </div>
          </div>

          <div className="w-full px-2 mb-4">
            <label
              htmlFor="projectId"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Project ID
            </label>
            <select
              id="project_id"
              name="project_id"
              onChange={handleInputChange}
              className="rounded-md appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
            >
              <option value="">select type</option>
              {customers?.customers?.map((data, idx) => (
                <option key={idx} value={data?.projectId}>
                  {data?.name}
                </option>
              ))}
            </select>
          </div>

          <div className="w-full px-2 mb-4">
            <label
              htmlFor="desc"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Issued Date
            </label>
            <Input
              type="datetime-local"
              id="issued_at"
              name="issued_at"
              onChange={handleInputChange}
              className="rounded-md appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
              placeholder="yyyy-MM-dd'T'HH:mm:ss"
            />
          </div>

          <div className="w-full px-2 mb-4">
            <label
              htmlFor="start_date"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Onset
            </label>
            <Input
              type="datetime-local"
              id="onset"
              name="onset"
              onChange={handleInputChange}
              className="rounded-md appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
              placeholder="Date"
            />
          </div>

          <div className="w-full px-2 mb-4">
            <label
              htmlFor="name"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Expiry
            </label>
            <Input
              type="datetime-local"
              id="expiry"
              name="expiry"
              onChange={handleInputChange}
              className="rounded-md appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
              placeholder="Date"
            />
          </div>

          <div className="w-full px-2 mb-4">
            <label
              htmlFor="name"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Repeat
            </label>
            <Select
              id="repeat"
              name="repeat"
              onChange={handleInputChange}
              placeholder="Select Repeat"
              className="rounded-md appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"

            >
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </Select>
          </div>
          <div className="w-full px-2 mb-4">
            <label
              htmlFor="desc"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Description
            </label>

            <Textarea
              id="description"
              name="description"
              onChange={handleInputChange}
              placeholder="write here ..."
            />

            <div className="w-full mt-2">
              <label
                htmlFor="desc"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Upload Document
              </label>
              <Input
                type="file"
                id="files"
                name="files"
                onChange={handleInputChange}
                className="rounded-md appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
                multiple
              />
            </div>

            <ul className="flex flex-wrap gap-4 mt-5">
              {base64Image.map((file, i) => (
                <li key={i} className="mb-2 flex">
                  <img
                    className="h-[60px] w-[100%] flex"
                    src={file?.base64}
                    alt={i}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <FormAction handleSubmit={handleSubmit} loading={loading} text="Submit" />
    </form>
  );
}
