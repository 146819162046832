import React, { useEffect, useState } from "react";
import Sidebar from "../components/Dashbord/Sidebar";
import Header from "../components/Dashbord/Header";
import { connect } from "react-redux";
import moment from "moment";
import { Document, Page, Text } from "@react-pdf/renderer";
import { usePdf } from "react-pdf-js"; // Importing usePdf instead of PDFViewer
import { Button, Input, Textarea, Stack } from "@chakra-ui/react";
import { MdAdd, MdAddBox } from "react-icons/md";
import {
  getContractById,
  getUser,
  logout,
  updateContract,
} from "../redux/actions";
import formatReadableDate from "../utilities/formatReadableDate";
import stringifyPrice from "../utilities/stringifyPrice";
import { textSlice } from "../utilities/textSlice";
import FormAction from "../components/FormAction";
import { useParams } from "react-router-dom";
const EditContract = ({
  loading,
  logoutUser,
  loadUser,
  user,
  getContract,
  contract,
  editContract,
}) => {
  const { id } = useParams();
  const [userData, setUserData] = useState(null);
  const [contractState, setContractState] = useState({
    name: "",
    description: "",
    issued_at: "",
    value: "",
    is_active: "",
    onset: "",
    expiry: "",
  });

  // Assuming you are populating the state from the API response
  useEffect(() => {
    if (contract) {
      const { name, description, value, is_active, issuedAt, onset, expiry } =
        contract;
      setContractState({
        name: name || "",
        issued_at: issuedAt || "",
        is_active: is_active || "",
        value: value || "",
        description: description || "",
        onset: onset || "",
        expiry: expiry || "",
      });
    }
  }, [contract]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContractState({
      ...contractState,
      [name]: value,
    });
  };

  useEffect(() => {
    loadUser();
    getContract(id);
  }, []);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("state"));
    if (items) {
      setUserData(items);
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    const { is_active, issued_at, value, onset, expiry, ...rest } =
      contractState;
    editContract(rest, id);
  };

  if (contract?.issuedAt) {
    const formattedDate = moment(
      contract?.issuedAt,
      "YYYY-MM-DDTHH:mm:ss.SSSZ",
      true
    );

    if (formattedDate.isValid()) {
      var curr = new Date(formattedDate);
      curr.setDate(curr.getDate());
      var date = curr.toISOString().substring(0, 10);
      console.log(date);
    } else {
      console.error("Invalid date format");
    }
  }
  console.log("vvvvvvvvvvvvv", contract);

  const documents = [
    {
      id: "65c880be0d8d57aac672e3b9",
      url: "http://res.cloudinary.com/df9lq4b6q/image/upload/v1707638973/lrwspy3rdqtfrjkkoi05.png",
      type: "image",
      createdAt: "2024-02-11T08:09:34.825Z",
      updatedAt: "2024-02-11T08:09:34.825Z",
      contractSessionId: "JlCdblfM1lgcR0hyNvTqa",
    },
    {
      id: "65c880be0d8d57aac672e3b9",
      url: "http://res.cloudinary.com/df9lq4b6q/image/upload/v1707901564/cukhr0hm6tucoeff8du2.pdf",
      type: "pdf",
      createdAt: "2024-02-11T08:09:34.825Z",
      updatedAt: "2024-02-11T08:09:34.825Z",
      contractSessionId: "JlCdblfM1lgcR0hyNvTqa",
    },
  ];

  const PdfRenderer = ({ url }) => {
    const [loading, error, pdfDocument] = usePdf({ url });

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    return (
      <div style={{ width: "100%", height: "500px" }}>
        {pdfDocument && (
          <iframe
            src={pdfDocument}
            title="PDF Viewer"
            width="100%"
            height="100%"
          />
        )}
      </div>
    );
  };

  return (
    <div className="flex h-screen bg-green-300">
      <div className="flex-1 flex flex-col overflow-hidden">
        <div className="flex h-full">
          <Sidebar logoutUser={logoutUser} loadUser={loadUser} />
          <main className="flex flex-col w-full bg-white overflow-x-hidden overflow-y-auto scrollbar-red">
            <Header logoutUser={logoutUser} user={user} name={"Settings"} />
            <div className=" flex justify-center w-full mx-auto gap-4 mt-5">
              <div
                className="w-5/12 ml-6 px-6"
                style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
              >
                <h4 className="mt-6 text-left text-xl font-extrabold text-gray-900">
                  CONTRACT INFORMATION
                </h4>
                <div className="mt-6">
                  <h1>
                    <b>Name:</b> {contract?.name}
                  </h1>
                  <h1 className="mt-4">
                    <b>Amount(value):</b> {stringifyPrice(contract?.value)}
                  </h1>
                  <h1 className="mt-4">
                    <b>Issued Date:</b> {formatReadableDate(contract?.issuedAt)}
                  </h1>
                  <h1 className="mt-4">
                    <b>Onset Date:</b> {formatReadableDate(contract?.onset)}
                  </h1>
                  <h1 className="mt-4">
                    <b>Expiry Date:</b> {formatReadableDate(contract?.expiry)}
                  </h1>
                  <h1 className="mt-4">
                    <b>Description:</b> {textSlice(contract?.description)}
                  </h1>
                </div>
              </div>

              <div
                className="w-7/12 ml-6 p-6"
                style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
              >
                {" "}
                {/* <h4 className="mt-6  text-left text-xl font-extrabold text-gray-900">
                  U Contract
                </h4> */}
                <form
                  className="md:mt-0 w-full md:max-w-[40rem] block justify-center space-y-6"
                  onSubmit={handleSubmit}
                >
                  <div className=" space-y-md w-full">
                    <div className="flex flex-wrap -mx-2">
                      <div className="w-full px-2 mb-4">
                        <label
                          htmlFor="name"
                          className="block text-gray-700 text-sm font-bold mb-2"
                        >
                          Name
                        </label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          value={contractState?.name}
                          onChange={handleInputChange}
                          className="rounded-md appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
                          placeholder="Enter your name"
                        />
                      </div>
                      {/* 
                      <div className="w-full px-2 mb-4">
                        <label
                          htmlFor="onset"
                          className="block text-gray-700 text-sm font-bold mb-2"
                        >
                          Service Issued Date
                        </label>
                        <input
                          type="date"
                          id="issued_at"
                          name="issued_at"
                          defaultValue={date}
                          onChange={handleInputChange}
                          className="rounded-md appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
                          placeholder=""
                        />
                      </div>
                      <div className="w-full px-2 mb-4">
                        <label
                          htmlFor="name"
                          className="block text-gray-700 text-sm font-bold mb-2"
                        >
                          Status
                        </label>
                        <select
                          id="is_active"
                          className="rounded-md appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
                          name="is_active"
                          value={contractState?.is_active}
                          onChange={handleInputChange}
                          placeholder="Select Repeat"
                        >
                          <option
                            value="yes"
                            selected={contractState?.is_active}
                          >
                            Yes
                          </option>
                          <option
                            value="no"
                            selected={!contractState?.is_active}
                          >
                            No
                          </option>
                        </select>
                      </div> */}
                      <div className="w-full px-2 mb-4">
                        <label
                          htmlFor="description"
                          className="block text-gray-700 text-sm font-bold mb-2"
                        >
                          Description
                        </label>
                        <Textarea
                          id="description"
                          name="description"
                          value={contractState?.description}
                          onChange={handleInputChange}
                          placeholder="Here is a sample placeholder"
                        />
                      </div>

                      {/* 
                                                <div className="w-full px-2 mb-4">
                                                    <label htmlFor="expiry" className="block text-gray-700 text-sm font-bold mb-2">
                                                    Service Due Date
                                                    </label>
                                                    <input
                                                        type="date"
                                                        id="expiry"
                                                        name="expiry"
                                                        defaultValue={expiry}
                                                        onChange={handleInputChange}
                                                        className="rounded-md appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
                                                        placeholder=""
                                                        disabled

                                                    />
                                                </div> */}
                    </div>
                  </div>
                  <FormAction
                    handleSubmit={handleSubmit}
                    loading={loading}
                    text="Submit"
                  />
                </form>
              </div>
            </div>

            <div
              className="w-12/12 ml-6 p-6 flex flex-col w-full"
              style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
            >
              {" "}
              <h4 className="mt-6 text-left text-xl font-extrabold text-gray-900">
                CONTRACT DOCUMENTS
              </h4>
              <>
                {contract?.documents?.map((document, index) => {
                  // Extracting file extension from URL
                  const fileExtension = document.url
                    .split(".")
                    .pop()
                    .toLowerCase();
                  return (
                    <div key={index}>
                      <h3 className="mb-2">Document {index + 1}</h3>
                      {(fileExtension === "png" ||
                        fileExtension === "jpg" ||
                        fileExtension === "jpeg") && (
                        <img
                          style={{
                            width: "100%",
                            height: "300px",
                            objectFit: "cover",
                          }}
                          src={document.url}
                          alt={`Document ${index + 1}`}
                        />
                      )}

                      {fileExtension === "pdf" && (
                        <Stack direction="row" spacing={4}>
                          <Button
                            onClick={() => window.open(document.url, "_blank")}
                            leftIcon={<MdAdd />}
                            // isLoading={loading}
                            loadingText="Submitting"
                            colorScheme="red"
                            size="sm"
                          >
                            Preview and Open File
                          </Button>
                        </Stack>
                      )}
                    </div>
                  );
                })}
              </>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};
const mapState = (state) => ({
  user: state.auth.user,
  contract: state.data.contract,
  loading: state.auth.loading,
});

const mapDispatch = (dispatch) => ({
  logoutUser: () => dispatch(logout()),
  editContract: (data, id) => dispatch(updateContract(data, id)),
  getContract: (id) => dispatch(getContractById(id)),
  loadUser: () => dispatch(getUser()),
});

export default connect(mapState, mapDispatch)(EditContract);
