import React from "react";
import formatReadableDate from "../../utilities/formatReadableDate";
import loadingGif from "../../images/loading-gif.gif";
import { Button } from "@chakra-ui/react";

const ScheduledPostDetailsModal = ({
  isScheduleOpen,
  closeViewModal,
  schedule,
  showDiv,
}) => {
  return (
    isScheduleOpen && (
      <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
        <div className="bg-white w-[500px] p-8 rounded-lg shadow-lg">
          <div className="flex justify-between items-center">
            <h2 className="text-2xl font-semibold mb-2">Schedule Details</h2>
            <button
              onClick={closeViewModal}
              className="text-white text-sm bg-red-500 px-2 h-6 rounded-lg cursor-pointer"
            >
              X
            </button>
          </div>
          <div>
            {showDiv ? (
              <div className="mt-10">
                <div className="mb-4">
                  <b className="mr-4">Name :</b> {schedule?.schedule?.name}
                </div>
                <div className="mb-4">
                  <b className="mr-4">Created Date :</b>{" "}
                  {formatReadableDate(schedule?.schedule?.createdAt)}
                </div>
                <div className="mb-4">
                  <b className="mr-4">Description :</b>{" "}
                  {schedule?.schedule?.desc}
                </div>
                <div className="mb-4">
                  <b className="mr-4">Status :</b>{" "}
                  {schedule?.schedule?.status === "COMPLETED" ? (
          <Button colorScheme="green" size="xs">
            Completed
          </Button>
        ) : schedule?.schedule?.status === "RUNNING" ? (
          <Button colorScheme="teal" size="xs">
            Running
          </Button>
        ) : schedule?.schedule?.status === "DEFERRED" ? (
          <Button colorScheme="blue" size="xs">
            Deferred
          </Button>
        ) : (
          <Button colorScheme="red" size="xs">
            Cancelled
          </Button>
        )}
                </div>
            
              </div>
            ) : (
              <img
                className="w-[220px] ml-[100px]"
                src={loadingGif}
                alt="Loading GIF"
              />
            )}
          </div>
        </div>
      </div>
    )
  );
};

export default ScheduledPostDetailsModal;
