/** @format */

import axios from "axios";
import { toast } from "react-toastify";
import {
  GET_CUSTOMERS,
  GET_SCHEDULES,
  GET_SCHEDULE,
  LOADING,
  UPDATED,
  GET_CUSTOMER,
  GET_CONTRACTS,
  GET_CONTRACT,
} from "../reducers/types";
import history from "./history";
import PAGES_URL from "../../router/routes";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
const appToken = localStorage.getItem("ReminderAppToken");

const headers = {
  headers: {
    Authorization: appToken,
  },
};
const headersContent = {
  headers: {
    'Content-Type': 'multipart/form-data', // Set the Content-Type header
    Authorization: appToken,
  },
};

export const addCustomer = (data) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  return axios
    .post(`${apiBaseUrl}/customers`, data, headers)
    .then((res) => {
      dispatch({ type: LOADING, payload: false });
      const { status_code, message } = res?.data;
      if (Number(status_code) === 201) {
        toast.success("Customer added successfully");
        dispatch({ type: UPDATED });
        setTimeout(() => {
          window.location.reload();
        }, 2500);
      } else {
        toast.error(message);
      }
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: false });
      toast.error(err?.response?.data?.message);
    });
};

export const getCustomers = () => (dispatch) => {
  axios
    .get(`${apiBaseUrl}/me/my_customers`, headers)
    .then((res) => {
      const { status_code, message, data } = res?.data;
      if (Number(status_code) === 200) {
        dispatch({ type: GET_CUSTOMERS, payload: data });
      } else {
        toast.error(message);
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message);
    });
};

export const addScheduledPost = (data) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });

  return axios
    .post(`${apiBaseUrl}/schedules`, data, headers)
    .then((res) => {
      dispatch({ type: LOADING, payload: false });
      const { status_code, message } = res?.data;
      if (Number(status_code) === 201) {
        toast.success("Schedule added successfully");
        dispatch({ type: UPDATED });
        setTimeout(() => {
          window.location.reload();
        }, 2500);
      } else {
        toast.error(message);
      }
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: false });
      toast.error(err?.response?.data?.message);
    });
};

export const getSchedules = (queryString) => (dispatch) => {
  axios
    // .get(`${apiBaseUrl}/me/my_schedules?${queryString}`, headers)
    .get(`${apiBaseUrl}/me/my_schedules`, headers)
    .then((res) => {
      const { status_code, message, data } = res?.data;
      if (Number(status_code) === 200) {
        dispatch({ type: GET_SCHEDULES, payload: data });
      } else {
        toast.error(message);
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message);
    });
};

export const getScheduleCheck = (queryString) => (dispatch) => {
  axios
    // .get(`${apiBaseUrl}/me/my_schedules?${queryString}`, headers)
    .get(`${apiBaseUrl}/schedules/check`, headers)
    .then((res) => {
      const { status_code, message, data } = res?.data;
      if (Number(status_code) === 200) {
        dispatch({ type: GET_SCHEDULES, payload: data });
      } else {
        toast.error(message);
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message);
    });
};

export const getSchedule = (id, reload) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  !reload && dispatch({ type: GET_SCHEDULE, payload: null });
  axios
    .get(`${apiBaseUrl}/schedules/${id}`, headers)
    .then((res) => {
      dispatch({ type: LOADING, payload: false });
      const { status_code, message, data } = res?.data;
      if (Number(status_code) === 200) {
        dispatch({ type: GET_SCHEDULE, payload: data });
      } else {
        toast.error(message);
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message);
    });
};

export const getCustomersById = (id) => (dispatch) => {
  axios
    .get(`${apiBaseUrl}/customers/${id}`, headers)
    .then((res) => {
      dispatch({ type: LOADING, payload: false });
      const { status_code, message, data } = res?.data;
      if (Number(status_code) === 200) {
        dispatch({ type: GET_CUSTOMER, payload: data });
      } else {
        toast.error(message);
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message);
    });
};

export const updateCustomer = (data, id) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  return axios
    .put(`${apiBaseUrl}/customers/${id}`, data, headers)
    .then((res) => {
      dispatch({ type: LOADING, payload: false });
      const { status_code, message, data } = res?.data;
      if (Number(status_code) === 200) {
        toast.success(message);
        dispatch({ type: UPDATED });
        setTimeout(() => {
          history.push(PAGES_URL.DASHBOARD_CUSTOMERS);
          window.location.reload();
        }, 2500);
      } else {
        toast.error(message);
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message);
    });
};

export const deleteCustomer = (id) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  return axios
    .delete(`${apiBaseUrl}/customers/${id}`, headers)
    .then((res) => {
      dispatch({ type: LOADING, payload: null });
      const { message } = res?.data;
      toast.success(message);
      dispatch({ type: UPDATED });
      setTimeout(() => {
        window.location.reload();
      }, 2500);
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err?.response?.data?.message);
    });
};

export const deleteSchedule = (id) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  return axios
    .delete(`${apiBaseUrl}/schedules/${id}`, headers)
    .then((res) => {
      dispatch({ type: LOADING, payload: null });
      const { message } = res?.data;
      toast.success(message);
      dispatch({ type: UPDATED });
      setTimeout(() => {
        window.location.reload();
      }, 2500);
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err?.response?.data?.message);
    });
};

export const addContract = (data) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  return axios
    .post(`${apiBaseUrl}/contracts`, data, headersContent)
    .then((res) => {
      dispatch({ type: LOADING, payload: false });
      const { status_code, message } = res?.data;
      if (Number(status_code) === 201) {
        toast.success("Contract added successfully");
        dispatch({ type: UPDATED });
        setTimeout(() => {
          window.location.reload();
        }, 2500);
      } else {
        toast.error(message);
      }
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: false });
      toast.error(err?.response?.data?.message);
    });
};

export const getContracts = () => (dispatch) => {
  axios
    .get(`${apiBaseUrl}/me/my_contracts`, headers)
    .then((res) => {
      const { status_code, message, data } = res?.data;
      if (Number(status_code) === 200) {
        dispatch({ type: GET_CONTRACTS, payload: data });
      } else {
        toast.error(message);
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message);
    });
};

export const updateContract = (data, id) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  return axios
    .put(`${apiBaseUrl}/contracts/${id}`, data, headers)
    .then((res) => {
      dispatch({ type: LOADING, payload: false });
      const { status_code, message, data } = res?.data;
      if (Number(status_code) === 200) {
        toast.success(message);
        dispatch({ type: UPDATED });
        setTimeout(() => {
          history.push(PAGES_URL.DASHBOARD_CONTRACTS);
          window.location.reload();
        }, 2500);
      } else {
        toast.error(message);
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message);
    });
};

export const getContract = (id, reload) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  !reload && dispatch({ type: GET_CONTRACT, payload: null });
  axios
    .get(`${apiBaseUrl}/contract/${id}`, headers)
    .then((res) => {
      dispatch({ type: LOADING, payload: false });
      const { status_code, message, data } = res?.data;
      if (Number(status_code) === 200) {
        dispatch({ type: GET_CONTRACT, payload: data });
      } else {
        toast.error(message);
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message);
    });
};
export const getContractById = (id) => (dispatch) => {
  axios
    .get(`${apiBaseUrl}/contracts/${id}`, headers)
    .then((res) => {
      dispatch({ type: LOADING, payload: false });
      const { status_code, message, data } = res?.data;
      if (Number(status_code) === 200) {
        dispatch({ type: GET_CONTRACT, payload: data });
      } else {
        toast.error(message);
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message);
    });
};

export const deleteContract = (id) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  return axios
    .delete(`${apiBaseUrl}/contracts/${id}`, headers)
    .then((res) => {
      dispatch({ type: LOADING, payload: null });
      const { message } = res?.data;
      toast.success(message);
      dispatch({ type: UPDATED });
      setTimeout(() => {
        window.location.reload();
      }, 2500);
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err?.response?.data?.message);
    });
};
