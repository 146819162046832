/** @format */

export const textSlice = (val, length) => {
  const text = val;
  let newText;
  for (let i = 0; i < (text && text?.length); i++) {
    if (text?.length > length) {
      newText = text.split("").slice(0, length).join("");
    } else {
      newText = text;
    }
  }
  return `${newText}${val?.length > length ? "..." : ""}`;
};
export const newSlice = (val, length) => {
  const mq1 = window.matchMedia("(min-width: 0px) and (max-width: 399.98px)");
  const mq2 = window.matchMedia("(min-width: 400px) and (max-width: 414.98px)");
  const mq3 = window.matchMedia("(min-width: 415px) and (max-width: 767.98px)");
  const mq4 = window.matchMedia(
    "(min-width: 768px) and (max-width: 1023.98px)"
  );
  const mq5 = window.matchMedia("(min-width: 1024px)");

  let newText;
  for (let i = 0; i < (val && val?.length); i++) {
    if (mq1.matches) {
      if (val?.length > length[0]) {
        newText = `${val.split("").slice(0, length[0]).join("")}...`;
      } else {
        newText = val + (val?.length > length[0] ? "..." : "");
      }
    }
    if (mq2.matches) {
      if (val?.length > length[1]) {
        newText =
          val.split("").slice(0, length[1]).join("") +
          (val?.length > length[1] ? "..." : "");
      } else {
        newText = val + (val?.length > length[1] ? "..." : "");
      }
    }
    if (mq3.matches) {
      if (val?.length > length[2]) {
        newText =
          val.split("").slice(0, length[2]).join("") +
          (val?.length > length[2] ? "..." : "");
      } else {
        newText = val + (val?.length > length[2] ? "..." : "");
      }
    }
    if (mq4.matches) {
      if (val?.length > length[3]) {
        newText =
          val.split("").slice(0, length[3]).join("") +
          (val?.length > length[3] ? "..." : "");
      } else {
        newText = val + (val?.length > length[3] ? "..." : "");
      }
    }
    if (mq5.matches) {
      if (val?.length > length[4]) {
        newText =
          val.split("").slice(0, length[4]).join("") +
          (val?.length > length[4] ? "..." : "");
      } else {
        newText = val + (val?.length > length[4] ? "..." : "");
      }
    }
  }
  return newText;
};
