/** @format */

const HOME = "/";

// Authentication routes
const LOGIN = "/login";
const SIGNUP = "/signup";
const FORGOT = "/password_reset";
const FORGOT_COMPLETE = "/password_reset_complete";

// Dashboard
const DASHBOARD = "/dashboard/home";
const DASHBOARD_SETTINGS = "/dashboard/settings";
const DASHBOARD_CUSTOMERS = "/dashboard/customers";
const DASHBOARD_CONTRACTS = "/dashboard/contracts";
const UPDATE_CUSTOMERS = "/dashboard/customers/:id";
const UPDATE_CONTRACT = "/dashboard/contracts/:id";
const DASHBOARD_SCHEDULE = "/dashboard/schedule";

const PAGES_URL = {
  HOME,
  // Authentication routes
  LOGIN,
  SIGNUP,
  FORGOT,
  FORGOT_COMPLETE,
  DASHBOARD,
  DASHBOARD_SETTINGS,
  DASHBOARD_CUSTOMERS,
  DASHBOARD_CONTRACTS,
  UPDATE_CUSTOMERS,
  UPDATE_CONTRACT,
  DASHBOARD_SCHEDULE,
};

export default PAGES_URL;
