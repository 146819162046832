/** @format */

import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import PAGES_URL from "./routes";

export const AuthRoute = ({ children }) => {
  const {
    auth: { authenticated },
  } = useSelector((s) => s);
  if (!authenticated) {
    return <Navigate to={PAGES_URL.HOME} />;
  }
  return children;
};

export default AuthRoute;
