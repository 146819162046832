import React, { useState, useEffect } from "react";
import Sidebar from "../components/Dashbord/Sidebar";
import Header from "../components/Dashbord/Header";
import { connect } from "react-redux";
import {
  getCustomers,
  getSchedules,
  getContracts,
  getUser,
  logout,
} from "../redux/actions";
import { Doughnut, Bar, Scatter } from "react-chartjs-2";
import ChartWithTickConfiguration from "../components/Dashbord/ChartWithTickConfiguration";
import { DateTime } from "luxon";

import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import qs from "qs";
ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = ({
  logoutUser,
  loadUser,
  user,
  loadCustomer,
  customers,
  loadSchedule,
  schedules,
  loadContracts,
  contracts,
}) => {
  const [limit, setScheduleFilterLimit] = useState(null);
  const [timeline_limit, setScheduleTimelineFilterLimit] = useState(null);
  const [year_filter, setYearFilter] = useState(null);
  const dateObject = new Date(year_filter);

  const year = dateObject.getFullYear();
  const month = dateObject.getMonth() + 1; // Months are zero-indexed, so add 1
  const day = dateObject.getDate(); // To get date

  const hasCustomers = customers?.customers?.length > 0;
  const hasSchedules = schedules?.schedules?.length > 0;
  const hasContracts = contracts?.length > 0;

  const queryObject = {
    limit: limit || null,
    timeline_limit: timeline_limit || null,
    year_filter: year_filter ? year : null,
    month_filter: year_filter ? month : null,
  };
  // Convert the object to a query string using qs.stringify
  const queryString = qs.stringify(queryObject);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      loadUser();
      loadCustomer();
      loadSchedule(queryString);
      loadContracts();
    }, 1100); // Adjust the delay time (in milliseconds) as needed

    return () => {
      clearTimeout(timeoutId); // Clear the timeout if the component unmounts before the delay
    };
  }, [queryString]);

  const data = {
    labels: ["Total Customers", "Total Schedules", 'Total Contracts'],
    datasets: [
      {
        data: [
          hasCustomers ? customers.customers.length : 1, // If no customers, set to 1 to avoid NaN in the chart
          hasSchedules ? schedules.schedules.length : 1, // If no schedules, set to 1 to avoid NaN in the chart
          hasContracts ? contracts.length : 1, // If no contracts, set to 1 to avoid NaN in the chart
        ],
        backgroundColor: [
          hasCustomers ? "red" : "gray",
          hasSchedules ? "purple" : "gray",
          hasContracts ? "blue" : "gray",
        ],
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
  };

  const optionsBar = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  // Create an array to store the counts for each month
  const monthCounts = Array(12).fill(0);
  schedules?.schedules?.forEach((schedule) => {
    const startDate = new Date(schedule?.createdAt);
    const month = startDate?.getMonth();
    monthCounts[month]++;
  });

  // Filter labels to include only months with non-zero counts
  const labels = monthCounts
    ?.map((count, index) => {
      if (count > 0) {
        return new Date(2023, index, 1).toLocaleString("en-US", {
          month: "long",
        });
      }
      return null;
    })
    .filter(Boolean);

  // Filter counts to include only non-zero counts
  const filteredCounts = monthCounts?.filter((count) => count > 0);
  const colors = [
    "rgba(255, 0, 0, 1)",
    "rgba(128, 0, 128, 1)",
    "rgba(0, 0, 0, 1)",
  ];
  const dataBar = {
    labels,
    datasets: [
      {
        label: "Monthly Schedule",
        data: filteredCounts, // Update with the filtered counts
        backgroundColor: colors,
        borderColor: colors,
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="flex h-screen bg-green-300">
      <div className="flex-1 flex flex-col overflow-hidden">
        <div className="flex h-full">
          <Sidebar logoutUser={logoutUser} loadUser={loadUser} />
          <main className="flex flex-col w-full bg-white overflow-x-hidden overflow-y-auto">
            <Header logoutUser={logoutUser} user={user} name={"Dashboard"} />
            <div className="flex w-full mx-auto px-4 sm:px-6 py-8">
              <div className="flex flex-col w-full text-gray-900 text-xl">
                <div className="grid grid-cols-12 gap-4">
                  {/* Card 1: Total No. of Customers */}
                  <div className="col-span-4">
                    <div className="bg-white p-4 shadow rounded-lg border-r-2 border-red-500">
                      <div className="text-gray-700 mb-5">Total Customers</div>
                      <div className="text-3xl font-bold">
                        {customers?.customers?.length}
                      </div>
                    </div>
                  </div>

                  {/* Card 2: Total Count */}
                  <div className="col-span-4">
                    <div className="bg-white p-4 shadow rounded-lg border-r-2 border-red-500">
                      <div className="text-gray-700 mb-5">Total schedules</div>
                      <div className="text-3xl font-bold">
                        {schedules?.schedules?.length}
                      </div>
                    </div>
                  </div>

                  {/* Card 3: Total Count */}
                  <div className="col-span-4">
                    <div className="bg-white p-4 shadow rounded-lg border-r-2 border-red-500">
                      <div className="text-gray-700 mb-5">Total Contracts</div>
                      <div className="text-3xl font-bold">
                        {contracts?.length > 0 ? contracts?.length : 0}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex text-gray-900 text-xl mt-5 mb-7">
              <div className="flex items-center justify-center w-1/2">
                <Doughnut data={data} options={options} />
              </div>
              <div className="flex items-center justify-center w-1/2">
                <Bar data={dataBar} options={optionsBar} />
              </div>
            </div>

            <>
              {schedules && (
                <ChartWithTickConfiguration schedulesData={schedules} />
              )}
            </>
            {/* <div className="w-full text-gray-900 text-xl">
              <div className="flex items-center justify-center gap-4">
                <div className="flex items-center gap-4 px-2 mb-4">
                  <label
                    htmlFor="year"
                    className="block text-gray-700 text-sm font-bold mb-2"
                  >
                    Filter by year & Month:
                  </label>
                  <input
                    type="date"
                    id="year_filter"
                    onChange={(e) => setYearFilter(e.target.value)}
                    name="year_filter"
                    placeholder="e.g 2023"
                    className="rounded-md appearance-none w-full px-3 py-2 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-b-blue-950  focus:border-b-blue-950 focus:z-10 sm:text-sm border-2 border-solid border-b-blue-950  !important"
                  />
                </div>
                <div className="flex items-center gap-4">
                  <label
                    htmlFor="schedule"
                    className="block text-gray-700 text-sm font-bold mb-2"
                  >
                    Filter by Schedule Limit
                  </label>
                  <div className="flex items-center">
                    <input
                      list="schedule"
                      id="limit"
                      onChange={(e) => setScheduleFilterLimit(e.target.value)}
                      name="limit"
                      className="rounded-md appearance-none w-[75px] h-[35px] px-3 py-2 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-200  focus:border-gray-200  focus:z-10 sm:text-sm border-2 border-solid border-gray-200 !important"
                    />
                    <datalist id="schedule">
                      <option value="">Select Number</option>
                      {Array.from({ length: 100 }, (_, index) => (
                        <option key={index + 1} value={index + 1}>
                          {index + 1}
                        </option>
                      ))}
                    </datalist>
                  </div>
                </div>

                <div className="flex items-center gap-4">
                  <label
                    htmlFor="timelines"
                    className="block text-gray-700 text-sm font-bold mb-2"
                  >
                    Filter by Timeline Limit
                  </label>
                  <div className="flex items-center">
                    <input
                      list="timelines"
                      id="timeline_limit"
                      onChange={(e) =>
                        setScheduleTimelineFilterLimit(e.target.value)
                      }
                      name="timeline_limit"
                      className="rounded-md appearance-none w-[75px] h-[35px] px-3 py-2 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-200  focus:border-gray-200  focus:z-10 sm:text-sm border-2 border-solid border-gray-200 !important"
                    />
                    <datalist id="timelines">
                      <option value="">Select Number</option>
                      {Array.from({ length: 100 }, (_, index) => (
                        <option key={index + 1} value={index + 1}>
                          {index + 1}
                        </option>
                      ))}
                    </datalist>
                  </div>
                </div>
              </div>
            </div> */}
          </main>
        </div>
      </div>
    </div>
  );
};
const mapState = (state) => ({
  user: state.auth.user,
  customers: state.data.customers,
  schedules: state.data.schedules,
  contracts: state.data.contracts,
});

const mapDispatch = (dispatch) => ({
  logoutUser: () => dispatch(logout()),
  loadUser: () => dispatch(getUser()),
  loadCustomer: () => dispatch(getCustomers()),
  loadContracts: () => dispatch(getContracts()),
  loadSchedule: (queryString) => dispatch(getSchedules(queryString)),
});

export default connect(mapState, mapDispatch)(Dashboard);
