import Header from "../../components/Header";
import ForgotPassword from "../../components/ForgotPassword";
import { connect } from "react-redux";
import { forgotPassword } from "../../redux/actions";
import PAGES_URL from "../../router/routes";
const ForgotPasswordPage = ({ loading, forgot }) => {
  return (
    <div className="flex items-center justify-center py-12 px-4 sm:px-6 lg:px-12">
      <div className=" max-w-md w-full space-y-8">
        <Header
          heading="Reset Your Password for Account Recovery"
          paragraph="Already have an account yet?"
          linkName="Login"
          linkUrl={PAGES_URL.LOGIN}
        />
        <ForgotPassword loading={loading} forgot={forgot} />
      </div>
    </div>
  );
};

const mapState = (state) => ({
  loading: state.auth.loading,
});

const mapDispatch = (dispatch) => ({
  forgot: (data) => dispatch(forgotPassword(data)),
});

export default connect(mapState, mapDispatch)(ForgotPasswordPage);
