import Header from "../../components/Header";
import Login from "../../components/Login";
import { connect } from "react-redux";
import { loginUser } from "../../redux/actions";
import PAGES_URL from "../../router/routes";
const LoginPage = ({ loading, login }) => {
  return (
    <>
      <div className="flex items-center justify-center py-12 px-4 sm:px-6 lg:px-12">
        <div className=" max-w-md w-full space-y-8">
          <Header
            heading="Login to your business annunity account"
            paragraph="You don't have an account yet?"
            linkName="Signup"
            linkUrl={PAGES_URL.SIGNUP}
          />
          <Login loading={loading} login={login} />
        </div>
      </div>
    </>
  );
};
const mapState = (state) => ({
  loading: state.auth.loading,
});

const mapDispatch = (dispatch) => ({
  login: (data) => dispatch(loginUser(data)),
});

export default connect(mapState, mapDispatch)(LoginPage);
