import { Link, useLocation } from "react-router-dom"; // Assuming you are using React Router

export default function SidebarItem({ to, children }) {
  const location = useLocation();

  // Check if the 'to' matches the current pathname
  const isActive = location.pathname.includes(to);
  return (
    <li
      className={`mb-6 ${
        isActive ? "bg-red-600 hover:bg-red-500 rounded-r-lg p-2 w-[180px]" : ""
      }`}
    >
      <Link
        to={to}
        className={`text-gray-300 text-[16px] hover:text-white ${
          isActive ? "text-white" : ""
        }`}
      >
        {children}
      </Link>
    </li>
  );
}
