import Header from "../../components/Header";
import Signup from "../../components/Signup";
import { connect } from "react-redux";
import { registerUser } from "../../redux/actions";
import PAGES_URL from "../../router/routes";

const SignupPage = ({ loading, register }) => {
  return (
    <div className="flex items-center justify-center py-12 px-4 sm:px-6 lg:px-12">
      <div className=" max-w-md w-full space-y-8">
        <Header
          heading="Create Your Account!"
          paragraph="Already have an account yet?"
          linkName="Login"
          linkUrl={PAGES_URL.LOGIN}
        />
        <Signup loading={loading} register={register} />
        {/*<Customers loading={loading} addNewCustomer={addNewCustomer} />*/}
      </div>
    </div>
  );
};

const mapState = (state) => ({
  loading: state.auth.loading,
});

const mapDispatch = (dispatch) => ({
  register: (data) => dispatch(registerUser(data)),
});

export default connect(mapState, mapDispatch)(SignupPage);
