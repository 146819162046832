import React, { useEffect, useState } from "react";
import Sidebar from "../components/Dashbord/Sidebar";
import Header from "../components/Dashbord/Header";
import { connect } from "react-redux";
import {
  addScheduledPost,
  getSchedules,
  getSchedule,
  deleteSchedule,
  getUser,
  logout,
  getCustomers,
} from "../redux/actions";
import ScheduledPostModal from "../components/Modal/ScheduledPostModal";
import "react-data-table-component-extensions/dist/index.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import qs from "qs";
import DatatableComponent from "../components/DatatableComponent";
import swal from "sweetalert";
import formatReadableDate from "../utilities/formatReadableDate";
import ScheduledPostDetailsModal from "../components/Modal/ScheduledPostDetailsModal";
import { Button } from "@chakra-ui/react";
const ScheduledPostPage = ({
  logoutUser,
  loadUser,
  user,
  loadCustomers,
  customers,
  loadSchedule,
  schedules,
  schedule,
  remove,
  getSchedule,
  loading,
  addSchedule,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isScheduleOpen, setIsScheduleOpen] = useState(false);
  const [showDiv, setShowDiv] = useState(false);
  const [year_filter, setYearFilter] = useState(null);
  const [month_filter, setMonthFilter] = useState(null);
  const [week_filter, setWeekFilter] = useState(null);
  const [subject_type_filter, setSubjectTypeFilter] = useState(null);
  const date = new Date(month_filter);
  const month = date.getMonth() + 1;
  const [selectedYear, setSelectedYear] = useState(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowDiv(true);
    }, 3500);
    return () => clearTimeout(timer); // Cleanup the timer to prevent memory leaks
  }, [showDiv]);

  const years = [];
  const currentYear = new Date().getFullYear();
  for (let year = currentYear; year <= currentYear + 100; year++) {
    years.push(year);
  }

  const handleYearChangee = (year) => {
    setSelectedYear(year);
  };
  const handleMonthChange = (date) => {
    setMonthFilter(date);
  };
  const handleYearChange = (date) => {
    setYearFilter(date);
  };

  const queryObject = {
    name_filter: null,
    subject_type_filter: subject_type_filter || null,
    year_filter: year_filter ? year_filter : null,
    month_filter: month_filter ? month : null,
    week_filter: week_filter ? week_filter : null,
  };

  if (year_filter) {
    queryObject.year_filter = year_filter;
  } else if (month_filter) {
    queryObject.month_filter = month;
  } else if (week_filter) {
    queryObject.week_filter = week_filter;
  }
  // Convert the object to a query string using qs.stringify
  const queryString = qs.stringify(queryObject);

  useEffect(() => {
    loadUser();
    loadSchedule(queryString);
    loadCustomers();
  }, [year_filter, month_filter, week_filter, queryString]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openViewModal = () => {
    setIsScheduleOpen(true);
  };

  const closeViewModal = () => {
    setIsScheduleOpen(false);
    setShowDiv(false);
  };

  const handleDelete = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this item!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        // Perform the delete action with the provided id
        remove(id);
        swal("Deleted!", "scheduled has been deleted!", "success");
        // You can also add any additional logic after the item is deleted
      } else {
        swal("Oops!", "scheduled not deleted!", "error");
      }
    });
  };

  const [limit, setPerLimit] = useState(10);

  const [columns, setColumns] = useState([
    {
      name: "Name",
      selector: "name",
      sortable: true,
    },
    // {
    //   name: "Description",
    //   selector: "desc",
    //   sortable: true,
    // },
    {
      name: "Created",
      cell: (row) => formatReadableDate(row?.createdAt),
      sortable: true,
    },
    {
      name: "Status",
      cell: (row) =>
        row?.status === "COMPLETED" ? (
          <Button colorScheme="green" size="xs">
            Completed
          </Button>
        ) : row?.status === "RUNNING" ? (
          <Button colorScheme="teal" size="xs">
            Running
          </Button>
        ) : row?.status === "DEFERRED" ? (
          <Button colorScheme="blue" size="xs">
            Deferred
          </Button>
        ) : (
          <Button colorScheme="red" size="xs">
            Cancelled
          </Button>
        ),
      sortable: true,
      button: true,
    },
    {
      cell: (row) => (
        <button
          className="group relative flex justify-center py-2 px-4 border border-transparent text-[10px] font-medium rounded-md text-white bg-purple-700"
          onClick={() => {
            getSchedule(row?.id);
            openViewModal();
          }}
        >
          View
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    // {
    //   cell: (row) => (
    //     <button
    //       className="group relative flex justify-center py-2 px-4 border border-transparent text-[10px] font-medium rounded-md text-white bg-red-600"
    //       onClick={() => handleDelete(row?.id)}
    //     >
    //       Delete
    //     </button>
    //   ),
    //   ignoreRowClick: true,
    //   allowOverflow: true,
    //   button: true,
    // },
  ]);
  const numbersArray = Array.from({ length: 55 }, (_, index) => index);
  return (
    <div className="flex h-screen bg-green-300">
      <div className="flex-1 flex flex-col overflow-hidden">
        <div className="flex h-full">
          <Sidebar logoutUser={logoutUser} loadUser={loadUser} />
          <main className="flex flex-col w-full bg-white overflow-x-hidden overflow-y-auto">
            <Header
              user={user}
              logoutUser={logoutUser}
              name={"Schedule Post"}
            />

            {/* <div className="flex justify-end mt-[-10px] px-5">
              <button
                className={`group relative flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 mt-10`}
                onClick={openModal}
              >
                Add Schedule
              </button>
            </div> */}
            <div className=" w-full mx-auto py-5 px-5">
            
              {/* <div className="w-full text-gray-900 text-xl">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
                  <div className="w-full sm:w-[250px] px-2 mb-4">
                    <label
                      htmlFor="year"
                      className="block text-gray-700 text-sm font-bold mb-2"
                    >
                      Filter by year:
                    </label>
                    <input
                      // list="browsers"
                      type="text"
                      id="year_filter"
                      onChange={(e) => setYearFilter(e.target.value)}
                      name="year_filter"
                      placeholder="e.g 2023"
                      className="rounded-md appearance-none w-full px-3 py-2 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-b-blue-950  focus:border-b-blue-950 focus:z-10 sm:text-sm border-2 border-solid border-b-blue-950  !important"
                    />
                  
                  </div>

                  <div className="w-full sm:w-full">
                    <label
                      htmlFor="month"
                      className="block text-gray-700 text-sm font-bold mb-2"
                    >
                      Filter by month:{" "}
                    </label>
                    <DatePicker
                      selected={month_filter}
                      onChange={handleMonthChange}
                      dateFormat="MM"
                      showMonthYearPicker
                    />
                  </div>

                  <div className="w-full sm:w-[250px] px-2 mb-4">
                    <label
                      htmlFor="week"
                      className="block text-gray-700 text-sm font-bold mb-2"
                    >
                      Filter by week:
                    </label>
                    <input
                      // list="weekData"
                      id="week_filter"
                      type="text"
                      onChange={(e) => setWeekFilter(e.target.value)}
                      name="week_filter"
                      placeholder="e.g 1 "
                      className="rounded-md appearance-none w-full px-3 py-2 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-b-blue-950  focus:border-b-blue-950  focus:z-10 sm:text-sm border-2 border-solid border-b-blue-950  !important"
                    />
                  </div>

                  <div className="w-full sm:w-[250px] px-2 mb-4">
                    <label
                      htmlFor="subject_type_filter"
                      className="block text-gray-700 text-sm font-bold mb-2"
                    >
                      Filter by subject type
                    </label>
                    <select
                      id="subject_type_filter"
                      name="subject_type_filter"
                      value={subject_type_filter}
                      onChange={(e) => setSubjectTypeFilter(e.target.value)}
                      className="block text-[16px] appearance-none w-full bg-white border-2 border-solid border-b-blue-950  !important hover:border-b-blue-950  px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                    >
                      <option value="">Select Type</option>
                      <option value="CUSTOMER">Customer</option>
                      <option value="PERSONAL">Personal</option>
                    </select>
                  </div>
                </div>
              </div> */}

              <DatatableComponent
                columnsRecord={columns}
                dataRecord={schedules?.schedules}
                limit={limit}
              />
            </div>
          </main>
        </div>
      </div>

      {/* ScheduledPostModal */}
      {/* <ScheduledPostModal
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        loading={loading}
        addSchedule={addSchedule}
        customers={customers}
      /> */}

      <>
        <ScheduledPostDetailsModal
          isScheduleOpen={isScheduleOpen}
          closeViewModal={closeViewModal}
          schedule={schedule}
          showDiv={showDiv}
        />
      </>
    </div>
  );
};
const mapState = (state) => ({
  user: state.auth.user,
  loading: state.auth.loading,
  schedule: state.data.schedule,
  schedules: state.data.schedules,
  customers: state.data.customers,
});

const mapDispatch = (dispatch) => ({
  logoutUser: () => dispatch(logout()),
  loadUser: () => dispatch(getUser()),
  getSchedule: (id) => dispatch(getSchedule(id)),
  loadSchedule: (queryString) => dispatch(getSchedules(queryString)),
  loadCustomers: () => dispatch(getCustomers()),
  addSchedule: (data) => dispatch(addScheduledPost(data)),
  remove: (id) => dispatch(deleteSchedule(id)),
});

export default connect(mapState, mapDispatch)(ScheduledPostPage);
