// import logo from './logo.svg';
import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PAGES_URL from "./router/routes";
import SuspenseFallback from "./components/typography/SuspenseFallback";
import SignupPage from "./pages/Auth/Signup";
import LoginPage from "./pages/Auth/Login";
import ForgotPasswordPage from "./pages/Auth/ForgotPassword";
import ForgotPasswordCompletePage from "./pages/Auth/ForgotPasswordComplete";
import DashboardPage from "./pages/Dashboard";
import SettingsPage from "./pages/Settings";
import CustomersPage from "./pages/Customers";
import ContractsPage from "./pages/Contracts";
import EditCustomer from "./pages/EditCustomer";
import EditContract from "./pages/EditContract";
import ScheduledPostPage from "./pages/ScheduledPost";
import { UnAuthRoute } from "./router/UnAuthRoute";
import AuthRoute from "./router/AuthRoute";
function App() {
  return (
    <div className="min-h-full h-screen">
      <React.Suspense fallback={<SuspenseFallback />}>
        <BrowserRouter>
          <Routes>
            <Route
              path={PAGES_URL.HOME}
              element={
                <UnAuthRoute>
                  <LoginPage />
                </UnAuthRoute>
              }
            />
            <Route
              path={PAGES_URL.LOGIN}
              element={
                <UnAuthRoute>
                  <LoginPage />
                </UnAuthRoute>
              }
            />
            <Route
              path={PAGES_URL.SIGNUP}
              element={
                <UnAuthRoute>
                  <SignupPage />
                </UnAuthRoute>
              }
            />
            <Route
              path={PAGES_URL.FORGOT}
              element={
                <UnAuthRoute>
                  <ForgotPasswordPage />
                </UnAuthRoute>
              }
            />
            <Route
              path={PAGES_URL.FORGOT_COMPLETE}
              element={
                <UnAuthRoute>
                  <ForgotPasswordCompletePage />
                </UnAuthRoute>
              }
            />
            <Route
              path={PAGES_URL.DASHBOARD}
              element={
                <AuthRoute>
                  <DashboardPage />
                </AuthRoute>
              }
            />
            <Route
              path={PAGES_URL.DASHBOARD_SETTINGS}
              element={
                <AuthRoute>
                  <SettingsPage />
                </AuthRoute>
              }
            />
            <Route
              path={PAGES_URL.DASHBOARD_CUSTOMERS}
              element={
                <AuthRoute>
                  <CustomersPage />
                </AuthRoute>
              }
            />
            <Route
              path={PAGES_URL.DASHBOARD_CONTRACTS}
              element={
                <AuthRoute>
                  <ContractsPage />
                </AuthRoute>
              }
            />
                <Route
              // path="/dashboard/customers/:id"
              path={PAGES_URL.UPDATE_CONTRACT}
              element={
                <AuthRoute>
                  <EditContract />
                </AuthRoute>
              }
            />
            <Route
              // path="/dashboard/customers/:id"
              path={PAGES_URL.UPDATE_CUSTOMERS}
              element={
                <AuthRoute>
                  <EditCustomer />
                </AuthRoute>
              }
            />
            <Route
              path={PAGES_URL.DASHBOARD_SCHEDULE}
              element={
                <AuthRoute>
                  <ScheduledPostPage />
                </AuthRoute>
              }
            />
          </Routes>
        </BrowserRouter>
      </React.Suspense>
    </div>
  );
}

export default App;
