import React from "react";
import 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
const ChartWithTimelines = ({schedulesData}) => {
  // const data = {
  //   status_code: 200,
  //   status: true,
  //   message: "User's schedule'",
  //   data: {
  //     schedules: [
  //       {
  //           id: "65bcb0be29c5fc2a304678ac",
  //           contractId: "65b3913446987e3df609d0a4",
  //           name: "Globatech",
  //           description: "This is globatech view",
  //           creatorAccountId: "65b38e6a46987e3df609d09f",
  //           status: "RUNNING",
  //           createdAt: "2024-02-02T09:07:10.431Z",
  //           updatedAt: "2024-02-02T09:07:10.431Z",
  //           timeSlices: [
  //               {
  //                   id: "65bcb0be29c5fc2a304678ad",
  //                   scheduleId: "65bcb0be29c5fc2a304678ac",
  //                   second: "0",
  //                   minute: "7",
  //                   hour: "15",
  //                   dayOfWeek: "4",
  //                   dayOfMonth: "29",
  //                   weekNumber: null,
  //                   month: "2",
  //                   year: "2024"
  //               }
  //           ]
  //       },
  //       {
  //           id: "65b391a446987e3df609d0a8",
  //           contractId: "65b391a446987e3df609d0a7",
  //           name: "Globatech",
  //           description: "this is Globatech deal",
  //           creatorAccountId: "65b38e6a46987e3df609d09f",
  //           status: "RUNNING",
  //           createdAt: "2024-01-26T11:04:04.412Z",
  //           updatedAt: "2024-01-26T11:04:04.412Z",
  //           timeSlices: [
  //               {
  //                   id: "65b391a446987e3df609d0a9",
  //                   scheduleId: "65b391a446987e3df609d0a8",
  //                   second: "0",
  //                   minute: "0",
  //                   hour: "7",
  //                   dayOfWeek: "5",
  //                   dayOfMonth: "25",
  //                   weekNumber: null,
  //                   month: "7",
  //                   year: "2025"
  //               }
  //           ]
  //       },
  //       {
  //           id: "65b3974046987e3df609d0ab",
  //           contractId: "65b3974046987e3df609d0aa",
  //           name: "Micosoft Az",
  //           description: "This is azutere",
  //           creatorAccountId: "65b38e6a46987e3df609d09f",
  //           status: "RUNNING",
  //           createdAt: "2024-01-26T11:28:00.597Z",
  //           updatedAt: "2024-01-26T11:28:00.597Z",
  //           timeSlices: [
  //               {
  //                   id: "65b3974046987e3df609d0ac",
  //                   scheduleId: "65b3974046987e3df609d0ab",
  //                   second: "0",
  //                   minute: "0",
  //                   hour: "7",
  //                   dayOfWeek: "1",
  //                   dayOfMonth: "30",
  //                   weekNumber: null,
  //                   month: "12",
  //                   year: "2024"
  //               },
  //               {
  //                 id: "65b3974046987e3df609d0ac",
  //                 scheduleId: "65b3974046987e3df609d0ab",
  //                 second: "0",
  //                 minute: "0",
  //                 hour: "9",
  //                 dayOfWeek: "1",
  //                 dayOfMonth: "20",
  //                 weekNumber: null,
  //                 month: "10",
  //                 year: "2025"
  //             }
  //           ]
  //       },
  //       {
  //           id: "65b78b024fdba68bfa8774b6",
  //           contractId: "65b78b014fdba68bfa8774b5",
  //           name: "Globatech",
  //           description: "this is Globatech deal",
  //           creatorAccountId: "65b38e6a46987e3df609d09f",
  //           status: "RUNNING",
  //           createdAt: "2024-01-29T11:24:50.177Z",
  //           updatedAt: "2024-01-29T11:24:50.177Z",
  //           timeSlices: [
  //               {
  //                   id: "65b78b024fdba68bfa8774b7",
  //                   scheduleId: "65b78b024fdba68bfa8774b6",
  //                   second: "0",
  //                   minute: "0",
  //                   hour: "7",
  //                   dayOfWeek: "5",
  //                   dayOfMonth: "25",
  //                   weekNumber: null,
  //                   month: "7",
  //                   year: "2025"
  //               }
  //           ]
  //       },
  //       {
  //           id: "65b7ada99f7aee602ac76666",
  //           contractId: "65b7ada99f7aee602ac76665",
  //           name: "Globatech",
  //           description: "this is Globatech deal",
  //           creatorAccountId: "65b38e6a46987e3df609d09f",
  //           status: "RUNNING",
  //           createdAt: "2024-01-29T13:52:41.366Z",
  //           updatedAt: "2024-01-29T13:52:41.366Z",
  //           timeSlices: [
  //               {
  //                   id: "65b7ada99f7aee602ac76667",
  //                   scheduleId: "65b7ada99f7aee602ac76666",
  //                   second: "0",
  //                   minute: "0",
  //                   hour: "7",
  //                   dayOfWeek: "5",
  //                   dayOfMonth: "25",
  //                   weekNumber: null,
  //                   month: "7",
  //                   year: "2025"
  //               }
  //           ]
  //       }
  //   ]
    
    
  //   },
  // };


  const Utils = {
    randomColor: (alpha = 1, tolerance = 5) => {
      const minChannelValue = tolerance;
      const maxChannelValue = 255 - tolerance;

      const r = Math.floor(
        Math.random() * (maxChannelValue - minChannelValue + 1) +
          minChannelValue
      );
      const g = Math.floor(
        Math.random() * (maxChannelValue - minChannelValue + 1) +
          minChannelValue
      );
      const b = Math.floor(
        Math.random() * (maxChannelValue - minChannelValue + 1) +
          minChannelValue
      );

      return `rgba(${r},${g},${b},${alpha})`;
    },
  };
  // const scheduleDataObject = data?.data || {};
  const scheduleDataObject = schedulesData || {};
  const schedules = scheduleDataObject?.schedules || [];

  const dynamicData = schedules?.map((schedule) => {
    return {
      label: schedule?.name,
      data: schedule?.timeSlices?.map((timeSlice) => {
        const dateTime = new Date(
          Date.UTC(
            timeSlice.year,
            timeSlice.month - 1, // Months are zero-indexed
            timeSlice.dayOfMonth,
            timeSlice.hour,
            timeSlice.minute
          )
        );
        return { x: dateTime, y: dateTime.getUTCHours(), scheduleName: schedule.name };
      }),
      borderColor: Utils.randomColor(),
      backgroundColor: Utils.randomColor(0.5),
      fill: false,
 
      borderWidth: 0.5,
      pointRadius: 8,
      pointHoverRadius: 10,
      tension: 0.2,
    };
  });

  
  const chartData = {
    datasets: dynamicData,
    
  };
  
  const chartOptions = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Schedule Time Lines",
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const dataset = context.dataset;
            const point = context.parsed;
            if (dataset.label && point) {
              const scheduleName = dataset.label;
              const date = new Date(point.x).toLocaleDateString();
              const time = new Date(point.x).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });
              return `Schedule: ${scheduleName}, Date: ${date}, Time: ${time}`;
            }
            return null;
          },
        },
      },
      
    },
    scales: {
      x: {
        type: 'time',
        title: {
          display: true,
          text: 'Date',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Hours',
        },
        ticks: {
          stepSize: 1,
          min: 0,
          max: 23,
        },
      },
    },
  };
  return (
    <>
      <Line data={chartData} options={chartOptions} />
    </>
  );
};

export default ChartWithTimelines;
