import React from "react";
import ScheduledPost from "../Dashbord/ScheduledPost";

const ScheduledPostModal = ({
  isModalOpen,
  closeModal,
  loading,
  addSchedule,
  customers,
}) => {
  return (
    isModalOpen && (
      <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
        <div className="bg-white w-[700px] p-8 rounded-lg shadow-lg">
          <div className="flex justify-between items-center">
            <h2 className="text-2xl font-semibold mb-2">Add New Schedule</h2>
            <button
              onClick={closeModal}
              className="text-white text-sm bg-red-500 px-2 h-6 rounded-lg cursor-pointer"
            >
              X
            </button>
          </div>
          <ScheduledPost
            loading={loading}
            addSchedule={addSchedule}
            customers={customers}
          />
        </div>
      </div>
    )
  );
};

export default ScheduledPostModal;
