/** @format */

import {
  registerUser,
  loginUser,
  getUser,
  forgotPassword,
  resetPasswordComplete,
  changePasswordFunc,
  logout,
} from "./authActions";

import {
  addCustomer,
  getCustomers,
  addScheduledPost,
  getSchedules,
  getSchedule,
  getScheduleCheck,
  updateCustomer,
  getCustomersById,
  deleteSchedule,
  addContract,
  getContracts,
  getContract,
  getContractById,
  updateContract,
  deleteContract,
} from "./dataActions";


const redirectPage = () => {
  localStorage.clear();
  window.location.href = "/";
};


export {
  // Auth
  registerUser,
  loginUser,
  getUser,
  forgotPassword,
  resetPasswordComplete,
  changePasswordFunc,
  logout,
  redirectPage,
  //Data
  addCustomer,
  getCustomers,
  addScheduledPost,
  getSchedules,
  getSchedule,
  getScheduleCheck,
  updateCustomer,
  getCustomersById,
  deleteSchedule,
  addContract,
  getContracts,
  getContract,
  getContractById,
  updateContract,
  deleteContract
};




// const handleChange = (e) => {
//   //If the input is a file (profile_picture), handle it separately
//   if (e.target.type === "file") {
//     const file = e.target.files[0];
//     const reader = new FileReader();

//     reader.onloadend = () => {
//       setBase64Image(reader.result);
//     };
//     reader.readAsDataURL(file);
//     setState({
//       ...state,
//       profile_picture: file, // Convert the image to base64
//     });
//   } else {
//     // For other fields, handle normally
//     setState({ ...state, [e.target.id]: e.target.value });
//   }
// };


// const { isLoading: createLoading, mutate: createPosts } = useCreatePosts();

// const handleSubmit = async (e) => {
//   e.preventDefault();

//   // Create a FormData object
//   const formData = new FormData();

//   // Append each field to the FormData object
//   Object.entries(state).forEach(([key, value]) => {
//     formData.append(key, value);
//   });

//   // Send the FormData to the createUser function
//   await createPosts(formData);
//   setState(null)
// };