import { useState } from "react";
import FormAction from "../FormAction";
import { Button, Input, Textarea, Stack } from "@chakra-ui/react";
import { MdAdd, MdAddBox } from "react-icons/md";
import generateCronPattern from "../../utilities/generateCronPattern";
export default function PostSchedule({ loading, addSchedule, contractObj }) {
  const [scheduleState, setScheduleState] = useState(false);
  const [postScheduleState, setPostScheduleState] = useState({
    name: "",
    project_id: contractObj?.projectId,
    contract_id: contractObj?.id,
    description: "",
    from_date: "",
    from_time: "",
    time_slices: [
      {
        minute: "",
        hour: "",
        day_of_month: "",
        month: "",
        day_of_week: "",
      },
    ],
  });
 

  const scheduleStateFunc = () => {
    setScheduleState(!scheduleState);
  };

  const handleInputChange = (e) => {
    // For other fields, handle normally
    let updatedPostScheduleState;

    if (e.target.id === "from_time" || e.target.id === "from_date") {
      const cronPattern = generateCronPattern(
        postScheduleState?.from_date,
        e.target.value
      );

      // Using the callback function to ensure state is updated before logging
      setPostScheduleState((prevState) => {
        const updatedState = {
          ...prevState,
          [e.target.id]: e.target.value,
          time_slices: cronPattern,
        };

        return updatedState;
      });
    } else {
      updatedPostScheduleState = {
        ...postScheduleState,
        [e.target.id]: e.target.value,
      };

      setPostScheduleState(updatedPostScheduleState);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const {from_date, from_time, ...rest}  = postScheduleState
    addSchedule(rest);
  };

  return (
    <form
      className="md:mt-8 w-full block justify-center space-y-6"
      onSubmit={handleSubmit}
    >
      <div className="space-y-md w-full">
        <div className="flex flex-wrap">
          <div className="w-full px-2 mb-4">
            <label
              htmlFor="name"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Name
            </label>
            <Input
              type="text"
              id="name"
              name="name"
              onChange={handleInputChange}
              className="rounded-md appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
              placeholder="Enter the name"
            />
          </div>

          {/* <div className="w-full px-2 mb-4">
            <label
              htmlFor="name"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Project ID
            </label>
            <Input
              type="text"
              id="project_id"
              name="project_id"
              onChange={handleInputChange}
              className="rounded-md appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
              placeholder="AMGD123"
            />
          </div>

          <div className="w-full px-2 mb-4">
            <label
              htmlFor="name"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Contract ID
            </label>
            <input
              type="text"
              id="contract_id"
              name="contract_id"
              onChange={handleInputChange}
              className="rounded-md appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
              placeholder="ACC-TIH-32"
              disabled
            />
          </div> */}

          <div className="w-full px-2 mb-4">
            <label
              htmlFor="desc"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Description
            </label>

            <Textarea
              id="description"
              name="description"
              onChange={handleInputChange}
              placeholder="write here ..."
            />
          </div>

          <div className="w-full px-2 mb-4">
            <div className="flex justify-between items-center">
              <p className="block text-gray-700 text-md font-bold mb-2">
                Timeslice
              </p>

              <Stack direction="row" spacing={4}>
                <Button
                  onClick={scheduleStateFunc}
                  leftIcon={<MdAdd />}
                  style={{ width: "100%" }}
                  // isLoading={loading}
                  loadingText="Submitting"
                  colorScheme="red"
                  size="xs"
                >
                  add reminder
                </Button>
              </Stack>
            </div>

            {scheduleState && (
              <div>
                <div className="flex w-full">
                  <div>
                    <label
                      htmlFor="Date"
                      className="block text-gray-700 text-[12px]"
                    >
                      Date
                    </label>
                    <Input
                      type="date"
                      id="from_date"
                      name="from_date"
                      onChange={handleInputChange}
                      className="rounded-md appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
                      placeholder="yyyy-MM-dd'T'HH:mm:ss"
                    />
                  </div>

                  <div className="w-full px-2 mb-4">
                    <label
                      htmlFor="Time"
                      className="block text-gray-700 text-[12px]"
                    >
                      Time
                    </label>
                    <Input
                      type="time"
                      id="from_time"
                      name="from_time"
                      onChange={handleInputChange}
                      className="rounded-md appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
                      placeholder="yyyy-MM-dd'T'HH:mm:ss"
                    />
                  </div>
                </div>
{/* 
                <Button
                  // onClick={scheduleStateFunc}
                  style={{ float: "right" }}
                  leftIcon={<MdAddBox />}
                  // isLoading={loading}
                  loadingText="Submitting"
                  colorScheme="blue"
                  size="sm"
                >
               Add More
                </Button> */}
              </div>
            )}
          </div>
        </div>
      </div>
      <FormAction handleSubmit={handleSubmit} loading={loading} text="Submit" />
    </form>
  );
}
