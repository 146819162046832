import React from "react";
import { Button, Stack } from "@chakra-ui/react";

export default function FormAction({
  handleSubmit,
  type = "Button",
  action = "submit",
  text,
  loading = false, // Add a loading prop with a default value of false
}) {
  return (
    <>
      <Stack direction="row" spacing={4}>
        <Button
          style={{ width: "100%" }}
          isLoading={loading}
          loadingText="Submitting"
          colorScheme={loading ? "purple" : "red"}
          type="submit"
        >
          Submit
        </Button>
      </Stack>
    </>
  );
}
