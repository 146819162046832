/** @format */

import {
  GET_CUSTOMER,
  GET_CUSTOMERS,
  GET_SCHEDULES,
  GET_SCHEDULE,
  GET_CONTRACTS,
  GET_CONTRACT,
} from "./types";

const initialState = {
  customer: null,
  customers: null,
  schedules: null,
  schedule: null,
  contracts: null,
  contract: null,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CUSTOMER:
      return { ...state, customer: action.payload };
    case GET_CUSTOMERS:
      return { ...state, customers: action.payload };
    case GET_SCHEDULES:
      return { ...state, schedules: action.payload };
    case GET_SCHEDULE:
      return { ...state, schedule: action.payload };
    case GET_CONTRACTS:
      return { ...state, contracts: action.payload };
    case GET_CONTRACT:
      return { ...state, contract: action.payload };
    default:
      return state;
  }
}
