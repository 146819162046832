import { useState } from "react";
import { passwordResetCompleteFields } from "../constants/formFields";
import Input from "./Input";
import FormAction from "./FormAction";
import { FiEye, FiEyeOff } from "react-icons/fi";
const fields = passwordResetCompleteFields;
let fieldsState = {};
fields.forEach((field) => (fieldsState[field.id] = ""));

export default function ForgotPasswordComplete({ loading, forgotComplete }) {
  const [passwordResetState, setPasswordResetState] = useState(fieldsState);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible((prev) => !prev);
  };
  const handleChange = (e) => {
    setPasswordResetState({
      ...passwordResetState,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    authenticateUser();
    forgotComplete(passwordResetState);
  };

  //to handle Password Reset API Integration
  const authenticateUser = () => {};

  return (
    <form
      className="mt-8 max-w-md block justify-center space-y-6"
      onSubmit={handleSubmit}
    >
      <div className="space-y-px">
        {fields.map((field) => (
          <div key={field.id} style={{ position: "relative" }}>
            <Input
              handleChange={handleChange}
              value={passwordResetState[field?.id]}
              labelText={field?.labelText}
              labelFor={field?.labelFor}
              id={field?.id}
              name={field?.name}
              type={
                field?.name === "newPassword"
                  ? passwordVisible
                    ? "text"
                    : "password"
                  : field.type
              }
              isRequired={field?.isRequired}
              placeholder={field?.placeholder}
            />
            {field?.name === "newPassword" && (
              <span
                onClick={togglePasswordVisibility}
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "70%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
              >
                {passwordVisible ? <FiEye /> : <FiEyeOff />}
              </span>
            )}
          </div>
        ))}
      </div>

      <FormAction
        handleSubmit={handleSubmit}
        loading={loading}
        text="Reset Password"
      />
    </form>
  );
}
