import React, { useEffect, useState } from "react";
import Sidebar from "../components/Dashbord/Sidebar";
import Header from "../components/Dashbord/Header";
import { connect } from "react-redux";
import { getUser, logout } from "../redux/actions";
import ChangePasswordPage from "./Auth/ChangePassword";
import { updateUser, changePasswordFunc } from "../redux/actions/authActions";
import { Button, Stack } from "@chakra-ui/react";

import { changePasswordFields } from "../constants/formFields";
import Input from "../components/Input";

const fields = changePasswordFields;
let fieldsState = {};
fields.forEach((field) => (fieldsState[field.id] = ""));

const Settings = ({ logoutUser, loadUser, user, updateUser }) => {
  const [profileLoading, setProfileLoading] = useState(false);
  const [profileState, setProfileState] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
  });
  const [passwordState, setpasswordState] = useState(fieldsState);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileState({
      ...profileState,
      [name]: value,
    });
  };
  console.log("USSSEERRR", user);
  const togglePasswordVisibility = () => {
    setPasswordVisible((prev) => !prev);
  };
  const handleChange = (e) => {
    setpasswordState({ ...passwordState, [e.target.id]: e.target.value });
  };

  useEffect(() => {
    if (user) {
      const { firstName, lastName, email, phoneNumber } = user;

      setProfileState({
        first_name: firstName || "",
        last_name: lastName || "",
        email: email || "",
        phone_number: phoneNumber || "",
      });
    }
  }, []);

  const handleProfileSubmit = async (e) => {
    e.preventDefault();
    setProfileLoading(true);
    const { email, phone_number, ...rest } = profileState;
    await updateUser(rest);
    setProfileLoading(false);
  };

  return (
    <div className="flex h-screen bg-green-300">
      <div className="flex-1 flex flex-col overflow-hidden">
        <div className="flex h-full">
          <Sidebar logoutUser={logoutUser} loadUser={loadUser} />
          <main className="flex flex-col w-full bg-white overflow-x-hidden overflow-y-auto">
            <Header logoutUser={logoutUser} user={user} name={"Settings"} />
            <div className="flex justify-center w-full mx-auto mt-5">
              <div
                className="ml-6 p-6 round-5"
                style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
              >
                {" "}
                <h4 className="text-left text-xl font-extrabold text-gray-900">
                  Profile
                </h4>
                <div className="md:mt-8 w-full md:max-w-[40rem] block justify-center space-y-6">
                  <div className=" space-y-md w-full">
                    <div className="flex flex-wrap -mx-2">
                      <div className="w-full px-2 mb-4">
                        <label
                          htmlFor="firstName"
                          className="block text-gray-700 text-sm font-bold mb-2"
                        >
                          First Name
                        </label>
                        <input
                          type="text"
                          id="first_name"
                          name="first_name"
                          value={profileState?.first_name}
                          onChange={handleInputChange}
                          className="rounded-md appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
                          placeholder="Enter your first name"
                        />
                      </div>
                      <div className="w-full px-2 mb-4">
                        <label
                          htmlFor="firstName"
                          className="block text-gray-700 text-sm font-bold mb-2"
                        >
                          Last Name
                        </label>
                        <input
                          type="text"
                          id="last_name"
                          name="last_name"
                          defaultValue={profileState?.last_name}
                          onChange={handleInputChange}
                          className="rounded-md appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
                          placeholder="Enter your last name"
                        />
                      </div>

                      <div className="w-full px-2 mb-4">
                        <label
                          htmlFor="desc"
                          className="block text-gray-700 text-sm font-bold mb-2"
                        >
                          Email Address
                        </label>
                        <input
                          type="text"
                          id="email"
                          name="email"
                          defaultValue={profileState?.email}
                          onChange={handleInputChange}
                          className="rounded-md appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
                          placeholder="Toba.Owolabi@arravo.co"
                          disabled
                        />
                      </div>
                      <div className="w-full px-2 mb-4">
                        <label
                          htmlFor="desc"
                          className="block text-gray-700 text-sm font-bold mb-2"
                        >
                          Phone Number
                        </label>
                        <input
                          type="text"
                          id="phone_number"
                          name="phone_number"
                          defaultValue={profileState?.phone_number}
                          onChange={handleInputChange}
                          className="rounded-md appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
                          placeholder="Enter Phone Number"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <Stack direction="row" spacing={4}>
                    <Button
                      style={{ width: "100%" }}
                      isLoading={profileLoading}
                      loadingText="Submitting"
                      colorScheme={profileLoading ? "purple" : "red"}
                      type="submit"
                      onClick={handleProfileSubmit}
                    >
                      Submit
                    </Button>
                  </Stack>
                </div>
              </div>
              <ChangePasswordPage />
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};
const mapState = (state) => ({
  user: state.auth.user,
  loading: state.auth.loading,

  loading_update: state.auth.loading_update,
});

const mapDispatch = (dispatch) => ({
  logoutUser: () => dispatch(logout()),
  loadUser: () => dispatch(getUser()),
  updateUser: (profileState) => dispatch(updateUser(profileState)),
  changePassword: (data) => dispatch(changePasswordFunc(data)),
});

export default connect(mapState, mapDispatch)(Settings);
